export default function (name) {
  switch (name.toLowerCase()) {
    case "name":
      return { text: "名稱", Width: 120 };
    case "storenumber":
      return { text: "門市編號", Width: 120 };
    case "storename":
      return { text: "門市名稱", Width: 150 };
    case "terminalnumber":
      return { text: "收銀台編號", Width: 120 };
    case "terminalname":
      return { text: "收銀台名稱", Width: 150 };

    case "foodkindnumber":
      return { text: "類別編號", Width: 120 };
    case "foodkindname":
    case "onlineordercategoryname":
      return { text: "類別名稱", Width: 120 };

    case "foodmajorkindnumber":
      return { text: "大類別編號", Width: 120 };
    case "foodmajorkindname":
      return { text: "大類別名稱", Width: 150 };

    case "barcode":
      return { text: "條碼", Width: 100 };
    case "barcode2":
      return { text: "條碼2", Width: 100 };
    case "barcode3":
      return { text: "條碼3", Width: 100 };

    case "departmentnumber":
      return { text: "部門編號", Width: 120 };
    case "departmentname":
      return { text: "部門名稱", Width: 150 };

    case "foodnumber":
      return { text: "商品編號", Width: 120 };
    case "onlineorderitemname":
    case "foodname":
      return { text: "商品名稱", Width: 150, WaxWidth: 300 };
    case "foodname2":
      return { text: "商品名稱2", Width: 150, WaxWidth: 300 };
    case "qty":
      return { text: "數量", Width: 100, class: "text-right" };
    case "safeqty":
      return { text: "安全存量", Width: 100, class: "text-right" };
    case "lackqty":
      return { text: "不足量", Width: 100, class: "text-right" };
    case "stockqty":
      return { text: "庫存量", Width: 100, class: "text-right" };
    case "inventoryqty":
      return { text: "盤點量", Width: 100, class: "text-right" };
    case "pickedqty":
      return { text: "已取量", Width: 100 };
    case "notpickedqty":
      return { text: "未取量", Width: 100 };
    case "salprice":
      return {
        text: "單價",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice1":
      return {
        text: "售價1",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice2":
      return {
        text: "售價2",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice3":
      return {
        text: "售價3",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice4":
      return {
        text: "售價4",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice5":
      return {
        text: "售價5",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salprice6":
      return {
        text: "售價6",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "cost":
      return {
        text: "標準成本",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "totalcost":
      return {
        text: "總成本(標準)",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "avgcost":
      return {
        text: "平均成本",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "totalavgcost":
      return {
        text: "總成本(平均)",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };

    case "differencetotalcost":
      return {
        text: "盈虧總成本",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "differencetotal":
      return {
        text: "盈虧總價",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "grossprofit":
    case "profit":
      return {
        text: "毛利",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "grossmargin":
      return {
        text: "毛利率",
        Width: 100,
        class: "text-right",
      };
    case "foodsaleproportion":
      return {
        text: "商品銷售比",
        Width: 100,
        class: "text-right",
      };
    case "saleproportion":
      return {
        text: "銷售比",
        Width: 100,
        class: "text-right",
      };
    case "total":
      return {
        text: "小計",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "saltotal":
    case "alltotal":
      return {
        text: "單據總計",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "saletotal":
      return {
        text: "銷售額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "salereturntotal":
      return {
        text: "銷退額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "totaldiscountprice":
    case "discountprice":
      return {
        text: "折扣金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "totalfreeprice":
    case "freeprice":
      return {
        text: "折讓金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "unit":
      return { text: "單位", Width: 100 };

    case "employeenumber":
      return { text: "員工編號", Width: 120 };
    case "employeename":
      return { text: "員工名稱", Width: 150 };

    case "salernumber":
      return { text: "業務員編號", Width: 120 };
    case "salername":
      return { text: "業務員名稱", Width: 150 };

    case "warehousenumber":
      return { text: "倉庫編號", Width: 120 };
    case "warehousename":
      return { text: "倉庫名稱", Width: 150 };

    case "fromwarehousenumber":
      return { text: "調出倉庫編號", Width: 120 };
    case "fromwarehousename":
      return { text: "調出倉庫名稱", Width: 150 };

    case "towarehousenumber":
      return { text: "調入倉庫編號", Width: 120 };
    case "towarehousename":
      return { text: "調入倉庫名稱", Width: 150 };

    case "suppliernumber":
      return { text: "廠商編號", Width: 120 };
    case "suppliername":
      return { text: "廠商名稱", Width: 150 };
    case "purchasetotal":
      return {
        text: "進貨金額",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "purchasereturntotal":
      return {
        text: "退貨金額",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "purchaseorderqty":
      return {
        text: "採購數量",
        Width: 120,
      };
    case "saleorderqty":
      return {
        text: "訂單數量",
        Width: 120,
      };

    case "salefoodname":
      return { text: "自訂商品", Width: 120 };

    case "customernumber":
      return { text: "會員編號", Width: 120 };
    case "customername":
      return { text: "會員名稱", Width: 150 };
    case "email":
      return { text: "電子信箱", Width: 100 };
    case "telephone":
      return { text: "電話", Width: 100 };
    case "cellphone":
      return { text: "手機", Width: 100 };
    case "memo":
      return { text: "備註", Width: 100 };
    case "tradememo":
    case "salmemo":
      return { text: "單據備註", Width: 100 };
    case "foodmemo":
      return { text: "明細備註", Width: 100 };
    case "sourcememo":
      return { text: "來源備註", Width: 120 };
    case "businessmemo":
      return { text: "行業別備註", Width: 120 };
    case "gender":
    case "gendertext":
      return { text: "性別", Width: 100 };
    case "username":
      return { text: "名稱", Width: 150 };
    case "roletype":
      return { text: "角色", Width: 120 };

    case "businessid":
      return { text: "營業人編號", Width: 120 };
    case "businessname":
      return { text: "營業人名稱", Width: 250 };

    case "combokindname":
      return { text: "套餐類別名稱", Width: 120 };

    case "comboname":
      return { text: "套餐名稱", Width: 150 };

    case "tastekindname":
    case "onlineordermodifiergroupname":
      return { text: "自訂項目名稱名稱", Width: 120 };

    case "tastename":
    case "onlineordermodifiername":
      return { text: "口味名稱", Width: 150 };

    case "price":
      return {
        text: "金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "invoicedate":
      return { text: "發票日期", Width: 120 };
    case "invoicenumber":
      return { text: "發票號碼", Width: 120 };
    case "period":
      return { text: "期別", Width: 120 };
    case "postalcode":
      return { text: "郵遞區號", Width: 100 };
    case "address":
      return { text: "地址", Width: 180 };
    case "phone":
      return { text: "電話", Width: 120 };
    case "invoiceemail":
      return { text: "寄送Email", Width: 180 };
    case "invoiceprizetype":
      return { text: "中獎獎別", Width: 150 };
    case "invoiceprizeamount":
      return { text: "中獎金額", Width: 120 };
    case "invoiceprizenumber":
      return { text: "中獎號碼", Width: 150 };

    case "businessnumber":
      return { text: "統一編號", Width: 120 };

    case "purchasenumber":
    case "salnumber":
    case "tradenumber":
      return { text: "單號", Width: 120 };
    case "purchaseordernumber":
      return { text: "採購單號", Width: 120 };
    case "depositrecordnumber":
      return { text: "儲值單號", Width: 120 };
    case "purchasedate":
    case "businessday":
      return { text: "日期", Width: 120 };
    case "businessclass":
      return { text: "班別", Width: 100 };

    case "stockadjustnumber":
      return { text: "調整單號", Width: 120 };
    case "stockadjustdate":
      return { text: "調整日期", Width: 120 };

    case "fromstockadjustnumber":
      return { text: "調出調整單號", Width: 120 };

    case "tostockadjustnumber":
      return { text: "調入調整單號", Width: 120 };

    case "stocktransfernumber":
      return { text: "調撥單號", Width: 120 };
    case "stocktransferdate":
      return { text: "調撥日期", Width: 120 };
    case "checkqty":
      return { text: "點收數量", Width: 120, class: "text-right" };
    case "checkername":
      return { text: "點收人", Width: 120 };

    case "salername":
      return { text: "業務員", Width: 150 };
    case "buildername":
      return { text: "建立者", Width: 150 };

    case "paytype":
      return { text: "付款別", Width: 100 };
    case "payname":
      return { text: "付款名稱", Width: 150 };

    case "functionname":
      return { text: "功能名稱", Width: 150 };
    case "value":
      return { text: "值", Width: 100 };
    case "directorytype":
    case "directorytypetext":
      return { text: "科目種類", Width: 100 };
    case "directorykindname":
      return { text: "科目類別名稱", Width: 120 };

    case "directoryname":
      return { text: "科目名稱", Width: 150 };

    case "moduletype":
      return { text: "模組種類", Width: 100 };
    case "modulename":
      return { text: "模組名稱", Width: 150 };

    case "promotionname":
      return { text: "群組名稱", Width: 150 };

    case "promotiondetname":
      return { text: "小群組名稱", Width: 150 };

    case "buyernumber":
      return { text: "採購員編號", Width: 120 };
    case "buyername":
      return { text: "採購員", Width: 150 };

    case "receiptnumber":
      return { text: "收款單號", Width: 120 };
    case "receiptdate":
      return { text: "收款日期", Width: 120 };

    case "paymentnumber":
      return { text: "付款單號", Width: 120 };
    case "paymentdate":
      return { text: "付款日期", Width: 120 };

    case "tradetype":
    case "tradetypetext":
      return { text: "交易別", Width: 120 };

    case "eattype":
    case "eattypetext":
      return { text: "用餐別", Width: 120 };

    case "payable":
      return {
        text: "原應付金額",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };

    case "payed":
      return {
        text: "已付金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "nopay":
      return {
        text: "未付金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "accountdate":
      return { text: "結帳日", Width: 120 };

    case "receivable":
      return {
        text: "應收金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "received":
      return {
        text: "已收金額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "downloadname":
      return { text: "名稱", Width: 150 };

    case "counts":
      return { text: "次數", Width: 80 };

    case "salecounts":
      return { text: "交易次數", Width: 100, class: "text-right" };

    case "voidcounts":
      return { text: "作廢次數", Width: 100, class: "text-right" };

    case "foodcounts":
      return { text: "商品數", Width: 100, class: "text-right" };

    case "people":
      return { text: "人數", Width: 100, class: "text-right" };

    case "time":
      return { text: "時段", Width: 200 };
    case "times":
      return { text: "次數", Width: 80 };

    case "invoicebegin":
      return { text: "起", Width: 120 };
    case "invoiceend":
      return { text: "訖", Width: 120 };
    case "ytaxprice":
      return {
        text: "淨額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "taxprice":
      return {
        text: "稅額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "ytaxpriceincludetax":
      return {
        text: "含稅額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "ztaxprice":
      return {
        text: "零稅額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "ntaxprice":
      return {
        text: "免稅額",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "difference":
      return {
        text: "盈虧",
        Width: 100,
        class: "text-right",
      };
    case "sheets":
      return { text: "張數", Width: 80 };

    case "invoicevoid":
      return { text: "作廢", Width: 100 };

    case "voidsheets":
      return { text: "作廢張數", Width: 80 };

    case "saltime":
    case "invoicetime":
      return { text: "銷售時間", Width: 200 };
    case "deliverytime":
      return { text: "出貨時間", Width: 200 };

    case "tradetime":
      return { text: "交易時間", Width: 200 };

    case "createtime":
    case "createtimetext":
      return { text: "建立時間", Width: 220 };

    case "updatetime":
    case "updatetimetext":
      return { text: "最後修改時間", Width: 200 };

    case "cash":
      return {
        text: "現金",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "change":
      return {
        text: "找零",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "uniformnumber":
      return { text: "統一編號", Width: 100 };

    case "inventorydate":
      return { text: "盤點日期", Width: 120 };

    case "inventorynumber":
      return { text: "盤點單號", Width: 120 };

    case "initialqty":
      return { text: "期初庫存量", Width: 120, class: "text-right" };

    case "lastqty":
      return { text: "期末庫存量", Width: 120, class: "text-right" };

    case "salqty":
      return { text: "銷售數量", Width: 120, class: "text-right" };
    case "voidsalqty":
      return { text: "銷退數量", Width: 120, class: "text-right" };
    case "purchaseqty":
      return { text: "進貨數量", Width: 120, class: "text-right" };
    case "purchaseprice":
      return { text: "進貨價格", Width: 120, class: "text-right" };
    case "purchasereturnqty":
      return { text: "進退數量", Width: 120, class: "text-right" };

    case "adjustqty":
      return { text: "調整數量", Width: 120, class: "text-right" };

    case "combinationinqty":
      return { text: "組合入庫", Width: 120, class: "text-right" };

    case "combinationoutqty":
      return { text: "領料扣庫", Width: 120, class: "text-right" };

    case "seperationinqty":
      return { text: "拆解扣庫", Width: 120, class: "text-right" };

    case "seperationoutqty":
      return { text: "退料入庫", Width: 120, class: "text-right" };

    case "transferinqty":
      return { text: "撥入", Width: 120, class: "text-right" };

    case "transferoutqty":
      return { text: "撥出", Width: 120, class: "text-right" };

    case "tradedate":
      return { text: "交易日期", Width: 120 };

    case "deviceid":
      return { text: "收銀台編號", Width: 250 };
    case "devicename":
      return { text: "收銀台名稱", Width: 180 };
    case "cpuid":
      return { text: "金鑰", Width: 250 };
    case "statustype":
    case "statustypetext":
      return { text: "狀態", Width: 100 };

    case "principlenumber":
      return { text: "負責人編號", Width: 120 };

    case "principlename":
      return { text: "負責人名稱", Width: 150 };

    case "daybegin":
      return { text: "開始日期", Width: 120 };

    case "dayend":
      return { text: "結束日期", Width: 120 };

    case "timebegin":
      return { text: "開始時間", Width: 100 };

    case "timeend":
      return { text: "結束時間", Width: 100 };

    case "sunday":
      return { text: "日", Width: 100 };
    case "monday":
      return { text: "一", Width: 100 };
    case "tuesday":
      return { text: "二", Width: 100 };
    case "wednesday":
      return { text: "三", Width: 100 };
    case "thursday":
      return { text: "四", Width: 100 };
    case "friday":
      return { text: "五", Width: 100 };
    case "saturday":
      return { text: "六", Width: 100 };
    case "daymodulename":
      return { text: "時間模組", Width: 150 };

    case "integral":
      return { text: "積點", Width: 100 };
    case "integraladd":
      return { text: "獲得積點", Width: 100 };
    case "integralminus":
      return { text: "折抵積點", Width: 100 };

    case "birth":
      return { text: "生日", Width: 100 };

    case "gender":
      return { text: "性別", Width: 100 };

    case "degree":
      return { text: "等級", Width: 100 };

    case "customerleveltypetext":
      return { text: "會員分級", Width: 100 };

    case "days":
      return { text: "累積天數", Width: 100 };
    case "memberupgraderule":
      return { text: "等級", Width: 100 };
    case "validmonths":
      return { text: "有效月份", Width: 150, class: "text-right" };
    case "keeptotal":
      return {
        text: "續會金額",
        Width: 150,
        class: "text-right",
        filter: "currency",
      };
    case "keepmonths":
      return { text: "續會統計月份", Width: 150, class: "text-right" };

    case "creditcardprice":
      return {
        text: "信用卡",
        Width: 150,
        class: "text-right",
        filter: "currency",
      };
    case "creditcardnumber":
      return {
        text: "信用卡號",
        Width: 100,
      };

    case "paytype1":
      return {
        text: "結帳別1",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "paytype2":
      return {
        text: "結帳別2",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "linepay":
    case "linepayprice":
      return {
        text: "LinePay",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "couponprice":
      return {
        text: "折價券",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "scan2pay":
    case "scan2payprice":
      return {
        text: "Scan2Pay",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "deposit":
      return {
        text: "儲值金",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "depositminus":
      return {
        text: "使用儲值金",
        Width: 150,
        class: "text-right",
        filter: "currency",
      };
    case "lastrechargeamount":
      return {
        text: "剩餘儲值金",
        Width: 150,
        class: "text-right",
        filter: "currency",
      };
    case "tradenumber":
      return {
        text: "單號",
        Width: 150,
      };
    case "executetime":
      return {
        text: "交易時間",
        Width: 150,
      };

    case "easycardprice":
      return {
        text: "悠遊卡",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "number":
      return { text: "號碼", Width: 100 };

    case "overcharge":
      return {
        text: "溢收",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "originalprice":
      return {
        text: "單價",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "originaltotal":
      return {
        text: "原總價",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "identifier":
      return { text: "識別碼", Width: 100 };
    case "totallength":
      return { text: "總長度", Width: 100 };
    case "pricelabeltype":
      return { text: "種類", Width: 100 };
    case "foodposition":
      return { text: "商品位置", Width: 100 };
    case "foodlength":
      return { text: "商品長度", Width: 100 };
    case "priceposition":
      return { text: "價格位置", Width: 100 };
    case "pricelength":
      return { text: "價格長度", Width: 100 };
    case "pricedecimalnumber":
      return { text: "價格小數位", Width: 100 };
    case "qtyposition":
      return { text: "數量位置", Width: 100 };
    case "qtylength":
      return { text: "數量長度", Width: 100 };
    case "qtydecimalnumber":
      return { text: "數量小數位", Width: 100 };

    case "discount":
      return { text: "折扣", Width: 100, filter: "discount" };

    case "towork":
      return { text: "上班時間", Width: 200 };
    case "offwork":
      return { text: "下班時間", Width: 200 };

    case "worktime":
      return { text: "工時", class: "text-right", Width: 150 };
    case "worktimehour":
      return { text: "工時(時)", class: "text-right", Width: 150 };
    case "worktimeminute":
      return { text: "工時(分)", class: "text-right", Width: 150 };

    case "devicecounts":
      return { text: "台數", class: "text-right", Width: 100 };

    case "principle":
      return { text: "負責人", Width: 120 };

    case "contactperson":
      return { text: "聯絡人", Width: 120 };

    case "startdate":
      return { text: "開始日期", Width: 200 };
    case "enddate":
      return { text: "結束日期", Width: 200 };
    case "months":
      return { text: "月", class: "text-right", Width: 100 };
    case "monthlyfee":
      return {
        text: "月租費",
        class: "text-right",
        Width: 120,
        filter: "currency",
      };

    case "freedeposit":
      return {
        text: "贈送儲值金",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "beforedeposit":
      return {
        text: "儲值金(前)",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "afterdeposit":
      return {
        text: "儲值金(後)",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "beforestock":
      return {
        text: "庫存量(前)",
        Width: 100,
        class: "text-right",
      };
    case "peopletype1":
      return { text: "客群1", Width: 100 };
    case "peopletype2":
      return { text: "客群2", Width: 100 };
    case "peopletype3":
      return { text: "客群3", Width: 100 };
    case "peopletype4":
      return { text: "客群4", Width: 100 };
    case "totalpeople":
      return { text: "總人數", Width: 100 };
    case "lywarehousenumber":
      return { text: "凌越倉庫編號", Width: 100 };
    case "lyuniformnumber":
      return { text: "凌越申報統編", Width: 100 };
    case "vehiclenumber":
      return { text: "載具號碼", Width: 100 };
    case "originalinvoice":
      return { text: "原發票號碼", Width: 100 };
    case "modulestorename":
      return { text: "門市模組名稱", Width: 100 };
    case "isfinalpaymentpayed":
      return { text: "已付尾款", Width: 100 };
    case "advanceddeposit":
      return { text: "訂金", Width: 100 };
    case "finalpayment":
      return { text: "尾款", Width: 100 };
    case "finalpaymentdate":
      return { text: "完成時間", Width: 100 };
    case "producttype":
    case "producttypetext":
      return { text: "產品名稱", Width: 200 };

    case "accountnumber":
      return { text: "帳戶編號", Width: 100 };
    case "accountname":
      return { text: "帳戶名稱", Width: 100 };
    case "couponname":
      return { text: "折價券名稱", Width: 100 };
    case "expiredate":
      return { text: "到期日", Width: 100 };
    case "sourcesalnumber":
      return { text: "來源單號", Width: 100 };
    case "sourcesaltime":
      return { text: "來源時間", Width: 100 };
    case "sourceterminalnumber":
      return { text: "來源收銀台編號", Width: 100 };
    case "sourceterminalname":
      return { text: "來源收銀台", Width: 100 };
    case "discountsalnumber":
      return { text: "使用單號", Width: 100 };
    case "discountsaltime":
      return { text: "使用時間", Width: 100 };
    case "discountterminalnumber":
      return { text: "使用收銀台編號", Width: 100 };
    case "discountterminalname":
      return { text: "使用收銀台", Width: 100 };
    case "onlineorderstorename":
      return { text: "商店名稱", Width: 100 };
    case "onlineorderphone":
      return { text: "商店電話", Width: 100 };
    case "onlineorderaddress":
      return { text: "商店地址", Width: 100 };
    case "collectionpaymenttype":
      return { text: "種類", Width: 100 };
    case "onlineordermenuname":
      return { text: "菜單名稱", Width: 100 };

    case "minqty":
      return { text: "最少", Width: 80 };
    case "maxqty":
      return { text: "最多", Width: 80 };
    case "taxtype":
    case "taxtypetext":
      return { text: "稅別", Width: 80 };

    case "starttime":
      return { text: "開始時間", Width: 200 };
    case "endtime":
      return { text: "結束時間", Width: 200 };

    case "combinationtradedate":
      return { text: "組合日期", Width: 100 };
    case "combinationtradenumber":
      return { text: "組合單號", Width: 100 };
    case "combinationwarehousenumber":
      return { text: "主件倉庫編號", Width: 150 };
    case "combinationwarehousename":
      return { text: "主件倉庫名稱", Width: 150 };
    case "combinationfoodnumber":
      return { text: "主件品號", Width: 100 };
    case "combinationfoodname":
      return { text: "主件品名", Width: 100 };
    case "combinationqty":
      return { text: "主件數量", Width: 100 };
    case "combinationtotal":
      return { text: "主件總計", Width: 100 };
    case "combinationunit":
      return { text: "主件單位", Width: 100 };
    case "seperationtradedate":
      return { text: "組合日期", Width: 100 };
    case "seperationtradenumber":
      return { text: "組合單號", Width: 100 };

    case "stockadjusttype":
    case "stockadjusttypetext":
      return { text: "調整類別", Width: 120 };

    case "promotiontype":
    case "promotiontypetext":
      return { text: "促銷種類", Width: 120 };

    case "balance":
      return {
        text: "沖帳金額",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };

    case "ocardruleid":
      return { text: "ID", Width: 120 };
    case "ocardrulename":
      return { text: "名稱", Width: 120 };
    case "ocardpromotiontype":
    case "ocardpromotiontypetext":
      return { text: "促銷規則", Width: 120 };
    case "ocardrulefoodnametext":
      return { text: "兌換商品", Width: 250 };
    case "promotionvalue":
      return { text: "值", Width: 120 };

    case "salesperson":
      return { text: "業務員", Width: 120 };
    case "partner":
      return { text: "購買公司", Width: 120 };
    case "utailocationtype":
    case "utailocationtypetext":
      return { text: "公司所在地", Width: 120 };
    case "applytype":
    case "applytypetext":
      return { text: "申請模式", Width: 120 };
    case "termleasetype":
    case "termleasetypetext":
      return { text: "使用期間", Width: 120 };
    case "companyname":
      return { text: "公司名稱", Width: 200 };
    case "companypostalcode":
      return { text: "公司郵遞區號", Width: 100 };
    case "companyaddress":
      return { text: "公司地址", Width: 120 };
    case "taxnumber":
      return { text: "稅籍編號", Width: 120 };
    case "taxationbureau":
      return { text: "所屬國稅局", Width: 120 };
    case "storepostalcode":
      return { text: "郵遞區號", Width: 100 };
    case "storeaddress":
      return { text: "門市地址", Width: 120 };
    case "principalname":
      return { text: "負責人姓名", Width: 120 };
    case "principalphone":
      return { text: "負責人電話", Width: 120 };
    case "principalfax":
      return { text: "負責人傳真", Width: 120 };
    case "principalemail":
      return { text: "負責人Email", Width: 150 };
    case "contactpersonphone":
      return { text: "聯絡人電話", Width: 120 };
    case "contactpersonfax":
      return { text: "聯絡人傳真", Width: 120 };
    case "contactpersonemail":
      return { text: "聯絡人Email", Width: 150 };
    case "invoicesheets":
      return { text: "每月預計開立發票", Width: 200 };
    case "invoiceincreasesheets":
      return { text: "增加發票張數", Width: 180 };
    case "maingoods":
      return { text: "主要販售商品", Width: 120 };
    case "receiver":
      return { text: "收件人", Width: 120 };
    case "receiveraddress":
      return { text: "收件地址", Width: 120 };
    case "receiverphone":
      return { text: "收件人電話", Width: 120 };
    case "applicationid":
      return { text: "ID", Width: 120 };
    case "ishead":
    case "isheadtext":
      return { text: "總分支機構", Width: 120 };
    case "extraprice":
      return {
        text: "加價",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "pgtalk":
      return {
        text: "PGTalk",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };
    case "pgtalkpt":
      return {
        text: "PGTalk PT",
        Width: 100,
        class: "text-right",
        filter: "currency",
      };

    case "inquiryid":
      return {
        text: "ID",
        Width: 120,
      };
    case "salertext":
      return {
        text: "業務員",
        Width: 120,
      };
    case "sourcetypetext":
      return {
        text: "來源",
        Width: 120,
      };
    case "businesstypetext":
      return {
        text: "行業別",
        Width: 120,
      };
    case "einvtypetext":
      return {
        text: "每月電子發票開立張數",
        Width: 200,
      };

    case "visiontype":
    case "visiontypetext":
      return {
        text: "處方",
        Width: 120,
      };
    case "builddate":
      return {
        text: "建立時間",
        Width: 200,
      };

    case "hasbatchnumber":
      return {
        text: "啟用批號",
        Width: 120,
      };
    case "batchnumber":
      return {
        text: "批號",
        Width: 200,
      };

    case "changepricetype":
    case "changepricetypetext":
      return {
        text: "促銷別",
        Width: 120,
      };
    case "salpricetype":
    case "salpricetypetext":
      return {
        text: "售價別",
        Width: 120,
      };

    case "sequence":
      return {
        text: "排序",
        Width: 100,
      };

    case "agencystartdate":
      return {
        text: "委任開始日期",
        Width: 200,
      };

    case "availablyqty":
      return {
        text: "可用量",
        Width: 120,
      };

    case "downloaditemname":
      return {
        text: "下載項目",
        Width: 200,
      };

    case "issuccess":
      return {
        text: "已下載",
        Width: 120,
      };
    case "onlineordermenu":
    case "onlineordermenutext":
      return {
        text: "菜單",
        Width: 120,
      };

    case "foodaveragesalprice":
      return {
        text: "商品平均單價",
        Width: 150,
      };
    case "totalperreceipt":
      return {
        text: "每單平均消費",
        Width: 150,
      };
    case "foodcountsperreceipt":
      return {
        text: "每單平均商品數",
        Width: 150,
      };
    case "peopleperreceipt":
      return {
        text: "每單平均人數",
        Width: 150,
      };
    case "totalperperson":
      return {
        text: "客單價",
        Width: 120,
      };
    case "foodcountsperperson":
      return {
        text: "每人平均商品數",
        Width: 150,
      };
    case "degreestartdate":
      return {
        text: "自動升等開始",
        Width: 120,
      };
    case "degreeenddate":
      return {
        text: "自動升等結束",
        Width: 120,
      };

    case "specificationtypename":
      return {
        text: "規格類型",
        Width: 120,
      };
    case "specificationname":
      return {
        text: "規格名稱",
        Width: 200,
      };

    case "terminalid":
      return {
        text: "收銀台ID",
        Width: 200,
      };
    case "apitype":
    case "apitypetext":
      return {
        text: "API類型",
        Width: 120,
      };
    case "requesturl":
      return {
        text: "請求網址",
        Width: 600,
      };
    case "requestmethod":
      return {
        text: "請求方法",
        Width: 120,
      };
    case "responsecode":
      return {
        text: "狀態碼",
        Width: 120,
      };
    case "issuccess":
    case "issuccesstext":
      return {
        text: "是否成功",
        Width: 120,
      };
    case "durationtime":
      return {
        text: "持續時間",
        Width: 120,
      };
    case "requesttime":
      return {
        text: "回應時間",
        Width: 250,
      };
    case "thirdpartytype":
    case "thirdpartytypetext":
      return {
        text: "第三方類型",
        Width: 120,
      };
    case "httpmethod":
      return {
        text: "請求方法",
        Width: 120,
      };
    case "durationseconds":
      return {
        text: "持續秒數",
        Width: 120,
      };
    case "userid":
      return {
        text: "使用者ID",
        Width: 200,
      };
    case "systemlogtype":
    case "systemlogtypetext":
      return {
        text: "系統紀錄類型",
        Width: 250,
      };
    case "description":
      return {
        text: "敘述",
        Width: 500,
      };
    case "notificationtype":
    case "notificationtypetext":
      return {
        text: "推播類型",
        Width: 200,
      };
    case "orderid":
      return {
        text: "訂單ID",
        Width: 200,
      };
    case "lastnotificationtime":
      return {
        text: "最後推播時間",
        Width: 250,
      };
    case "resendtimes":
      return {
        text: "傳送次數",
        Width: 200,
      };
    case "webhooklogid":
      return {
        text: "回呼紀錄ID",
        Width: 200,
      };
    case "webhooktype":
    case "webhooktypetext":
      return {
        text: "回呼類型",
        Width: 200,
      };
    case "clientip":
      return {
        text: "客戶端IP",
        Width: 150,
      };
    case "transactionid":
      return {
        text: "交易ID",
        Width: 200,
      };
    case "errormsg":
      return {
        text: "錯誤訊息",
        Width: 200,
      };
    case "signature":
      return {
        text: "簽名",
        Width: 120,
      };
    case "id":
      return {
        text: "ID",
        Width: 80,
      };
    case "apptype":
    case "apptypetext":
      return {
        text: "App Type",
        Width: 120,
      };

    case "invoicetime":
      return {
        text: "發票時間",
        Width: 200,
      };

    case "floormapid":
      return {
        text: "樓層ID",
        Width: 200,
      };
    case "tablemapid":
      return {
        text: "桌位ID",
        Width: 200,
      };
    case "tablemapname":
      return {
        text: "桌位名稱",
        Width: 120,
      };
    case "partid":
      return {
        text: "桌位ID",
        Width: 200,
      };
    case "authcode":
      return {
        text: "授權碼",
        Width: 120,
      };
    case "creditcardnumber":
      return {
        text: "卡號",
        Width: 200,
      };
    case "creditcardtradenumber":
      return {
        text: "交易序號",
        Width: 200,
      };
    case "brandname":
      return {
        text: "品牌名稱",
        Width: 120,
      };
    case "brandnumber":
      return {
        text: "品牌編號",
        Width: 120,
      };
    case "broadcastid":
      return {
        text: "Broadcast Id",
        Width: 200,
      };
    case "version":
      return {
        text: "版本",
        Width: 120,
      };
    case "iosmpccounts":
      return {
        text: "子機數量",
        Width: 120,
      };

    case "ubereats":
    case "ubereatsprice":
      return {
        text: "UberEats",
        Width: 120,
      };
    case "purchasememo":
      return {
        text: "單據備註",
        Width: 200,
      };
    case "tablemapname":
      return {
        text: "桌位名稱",
        Width: 120,
      };

    case "receivable":
      return {
        text: "應收帳款",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "retailprice":
      return {
        text: "零售價",
        Width: 120,
        class: "text-right",
        filter: "currency",
      };
    case "iserp":
      return {
        text: "進銷存",
        Width: 120,
      };
    case "isdining":
      return {
        text: "後結",
        Width: 120,
      };
    case "industrytype":
    case "industrytypetext":
      return {
        text: "行業別",
        Width: 120,
      };
    case "expirationdate":
      return {
        text: "到期時間",
        Width: 150,
      };
    case "checkoutinterfacetype":
    case "checkoutinterfacetypetext":
      return {
        text: "收銀畫面類型",
        Width: 120,
      };
    case "showprice":
      return {
        text: "價格",
        Width: 120,
        class: "text-right",
      };
    case "promotionmodetype":
    case "promotionmodetypetext":
      return {
        text: "贈送方式",
        Width: 120,
      };
    case "actualsaleqty":
      return {
        text: "實際銷量",
        Width: 120,
      };
    default:
      return { text: "", Width: 100 };
  }
}
