<template>
  <item-modal
    :isShowModal="isShow"
    :title="modalTitle"
    @onSaved="save"
    @onClosed="close"
  >
    <div slot="item">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="menuName">{{ $t("modal.name") }}</label>
            <input
              id="menuName"
              type="text"
              class="form-control"
              v-model="menu.OnlineOrderMenuName"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="dayModuleId">{{ $t("modal.daymodule") }}</label>
            <select
              id="dayModuleId"
              class="form-control"
              v-model="menu.DayModuleId"
            >
              <option
                v-for="dayModule in dayModules"
                :value="dayModule.ModuleId"
                :key="dayModule.ModuleId"
              >
                {{ dayModule.ModuleName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="eatType">{{ $t("modal.eattype") }}</label>
            <select id="eatType" class="form-control" v-model="menu.EatType">
              <option
                v-for="eatType in eatTypes"
                :value="eatType.value"
                :key="eatType.value"
              >
                {{ eatType.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      menu: {},
      dayModules: [],
      eatTypes: [],
    };
  },
  props: ["isShow", "entity", "modalTitle"],
  watch: {
    entity(val) {
      this.menu = val;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    save() {
      const vm = this;
      this.$api.onlineOrderMenus
        .post(vm.menu)
        .then(() => {
          vm.$emit("save");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    const vm = this;
    this.eatTypes = this.$enum.eatType.getByUber();
    this.$api.modules.getDayModules().then((data) => (vm.dayModules = data));
  },
};
</script>
