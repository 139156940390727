import i18n from "@/langs/i18n";

export function getAll() {
  return [
    {
      value: 0,
      name: "Cash",
      text: `${i18n.t("option.payType.Cash")}`,
    },
    {
      value: 1,
      name: "CreditCard",
      text: `${i18n.t("option.payType.CreditCard")}`,
    },
    {
      value: 2,
      name: "EasyCard",
      text: `${i18n.t("option.payType.EasyCard")}`,
    },
    {
      value: 3,
      name: "Coupon",
      text: `${i18n.t("option.payType.Coupon")}`,
    },
    {
      value: 4,
      name: "PayType1",
      text: `${i18n.t("option.payType.PayType1")}`,
    },
    {
      value: 5,
      name: "PayType2",
      text: `${i18n.t("option.payType.PayType2")}`,
    },
    {
      value: 6,
      name: "Receivable",
      text: `${i18n.t("option.payType.Receivable")}`,
    },
    {
      value: 8,
      name: "Deposit",
      text: `${i18n.t("option.payType.Deposit")}`,
    },
    {
      value: 9,
      name: "LinePay",
      text: `${i18n.t("option.payType.LinePay")}`,
    },
    {
      value: 10,
      name: "CouponForStock",
      text: `${i18n.t("option.payType.CouponForStock")}`,
    },
    {
      value: 11,
      name: "iPASS",
      text: `${i18n.t("option.payType.iPASS")}`,
    },
    {
      value: 12,
      name: "PXPay",
      text: `${i18n.t("option.payType.PXPay")}`,
    },
    {
      value: 13,
      name: "Scan2Pay",
      text: `${i18n.t("option.payType.Scan2Pay")}`,
    },
    {
      value: 14,
      name: "PGTalk",
      text: `${i18n.t("option.payType.PGTalk")}`,
    },
    {
      value: 15,
      name: "JKOPay",
      text: `${i18n.t("option.payType.JKOPay")}`,
    },
  ];
}

export function getForProductType(productType) {
  switch (productType) {
    case 0:
      return [
        {
          value: 0,
          name: "Cash",
          text: `${i18n.t("option.payType.Cash")}`,
        },
        {
          value: 1,
          name: "CreditCard",
          text: `${i18n.t("option.payType.CreditCard")}`,
        },
        {
          value: 2,
          name: "EasyCard",
          text: `${i18n.t("option.payType.EasyCard")}`,
        },
        {
          value: 3,
          name: "Coupon",
          text: `${i18n.t("option.payType.Coupon")}`,
        },
        {
          value: 4,
          name: "PayType1",
          text: `${i18n.t("option.payType.PayType1")}`,
        },
        {
          value: 5,
          name: "PayType2",
          text: `${i18n.t("option.payType.PayType2")}`,
        },
        {
          value: 6,
          name: "Receivable",
          text: `${i18n.t("option.payType.Receivable")}`,
        },
        {
          value: 8,
          name: "Deposit",
          text: `${i18n.t("option.payType.Deposit")}`,
        },
        {
          value: 9,
          name: "LinePay",
          text: `${i18n.t("option.payType.LinePay")}`,
        },
        {
          value: 10,
          name: "CouponForStock",
          text: `${i18n.t("option.payType.CouponForStock")}`,
        },
        {
          value: 11,
          name: "iPASS",
          text: `${i18n.t("option.payType.iPASS")}`,
        },
        {
          value: 12,
          name: "PXPay",
          text: `${i18n.t("option.payType.PXPay")}`,
        },
        {
          value: 13,
          name: "Scan2Pay",
          text: `${i18n.t("option.payType.Scan2Pay")}`,
        },
        {
          value: 14,
          name: "PGTalk",
          text: `${i18n.t("option.payType.PGTalk")}`,
        },
        {
          value: 15,
          name: "JKOPay",
          text: `${i18n.t("option.payType.JKOPay")}`,
        },
      ];
    case 2:
      return [
        {
          value: 0,
          name: "Cash",
          text: `${i18n.t("option.payType.Cash")}`,
        },
        {
          value: 1,
          name: "CreditCard",
          text: `${i18n.t("option.payType.CreditCard")}`,
        },
        {
          value: 4,
          name: "PayType1",
          text: `${i18n.t("option.payType.PayType1")}`,
        },
        {
          value: 5,
          name: "PayType2",
          text: `${i18n.t("option.payType.PayType2")}`,
        },
        {
          value: 6,
          name: "Receivable",
          text: `${i18n.t("option.payType.Receivable")}`,
        },
        {
          value: 8,
          name: "Deposit",
          text: `${i18n.t("option.payType.Deposit")}`,
        },
        {
          value: 9,
          name: "LinePay",
          text: `${i18n.t("option.payType.LinePay")}`,
        },
        {
          value: 12,
          name: "PXPay",
          text: `${i18n.t("option.payType.PXPay")}`,
        },
        {
          value: 14,
          name: "PGTalk",
          text: `${i18n.t("option.payType.PGTalk")}`,
        },
      ];
    case 4:
      return [
        {
          value: 0,
          name: "Cash",
          text: `${i18n.t("option.payType.Cash")}`,
        },
        {
          value: 1,
          name: "CreditCard",
          text: `${i18n.t("option.payType.CreditCard")}`,
        },
        {
          value: 4,
          name: "PayType1",
          text: `${i18n.t("option.payType.PayType1")}`,
        },
        {
          value: 5,
          name: "PayType2",
          text: `${i18n.t("option.payType.PayType2")}`,
        },
        {
          value: 6,
          name: "Receivable",
          text: `${i18n.t("option.payType.Receivable")}`,
        },
        {
          value: 9,
          name: "LinePay",
          text: `${i18n.t("option.payType.LinePay")}`,
        },
        {
          value: 12,
          name: "PXPay",
          text: `${i18n.t("option.payType.PXPay")}`,
        },
        {
          value: 14,
          name: "PGTalk",
          text: `${i18n.t("option.payType.PGTalk")}`,
        },
      ];
    default:
      return [];
  }
}

export function getForSale() {
  return [
    {
      value: 0,
      name: "Cash",
      text: `${i18n.t("option.payType.Cash")}`,
    },
    {
      value: 1,
      name: "CreditCard",
      text: `${i18n.t("option.payType.CreditCard")}`,
    },
    {
      value: 4,
      name: "PayType1",
      text: `${i18n.t("option.payType.PayType1")}`,
    },
    {
      value: 5,
      name: "PayType2",
      text: `${i18n.t("option.payType.PayType2")}`,
    },
    {
      value: 6,
      name: "Receivable",
      text: `${i18n.t("option.payType.Receivable")}`,
    },
  ];
}
export function getForAccount() {
  return [
    {
      value: 0,
      name: "Cash",
      text: `${i18n.t("option.payType.Cash")}`,
    },
    {
      value: 1,
      name: "CreditCard",
      text: `${i18n.t("option.payType.CreditCard")}`,
    },
    {
      value: 4,
      name: "PayType1",
      text: `${i18n.t("option.payType.PayType1")}`,
    },
    {
      value: 5,
      name: "PayType2",
      text: `${i18n.t("option.payType.PayType2")}`,
    },
  ];
}

export function getByName(name) {
  const type = getAll().find(function (item) {
    return item.name.toLowerCase() == name.toLowerCase();
  });
  return type;
}
