<template>
  <item-modal
    @onClosed="modalTrigger = !modalTrigger"
    @onSaved="onSaved"
    :title="modalTitle"
    :trigger="modalTrigger"
    modalId="promotionDet"
  >
    <div slot="item">
      <div class="row">
        <div class="col">
          <div class="form-group row">
            <label for="promotionDetName" class="col-2 col-form-label">{{
              $t("modal.name")
            }}</label>
            <div class="col-10">
              <input
                type="text"
                ref="name"
                class="form-control"
                id="promotionDetName"
                v-model="promotionDet.PromotionDetName"
                required
              />
            </div>
          </div>
          <div class="form-group row" v-if="promotionType != 2">
            <label for="fullNumber" class="col-2 col-form-label">{{
              label1
            }}</label>
            <div class="col-4">
              <input
                type="number"
                class="form-control"
                id="fullNumber"
                v-model="promotionDet.FullNumber"
                required
              />
            </div>
            <label for="total" class="col-2 col-form-label">{{ label2 }}</label>
            <div class="col-3">
              <input
                type="number"
                class="form-control"
                id="total"
                v-model="promotionDet.Total"
                required
              />
            </div>
            <label for="total" class="col-1 col-form-label">{{ label3 }}</label>
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      promotionDet: {},
      modalTrigger: false,
      label1: "",
      label2: "",
      label3: "",
    };
  },
  props: ["trigger", "id", "promotionId", "promotionType", "modalTitle"],
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setLabels();
    },
    trigger() {
      if (!this.id || this.id.length == 0) {
        this.promotionDet = { PromotionId: this.promotionId };
      } else {
        const vm = this;
        this.$api.promotionDets.get(this.id).then((data) => {
          vm.promotionDet = data;
        });
      }
      this.setLabels();
      this.modalTrigger = !this.modalTrigger;
    },
  },
  components: {
    itemModal,
  },
  methods: {
    setLabels() {
      switch (this.promotionType) {
        case 0:
          this.label1 = this.$t("modal.satisfy");
          this.label2 = this.$t("modal.amount");
          this.label3 = this.$t("modal.ntd");
          break;
        case 1:
          this.label1 = this.$t("modal.buy");
          this.label2 = this.$t("modal.present");
          this.label3 = this.$t("modal.amount");
          break;
        case 3:
        case 7:
          this.label1 = this.$t("modal.satisfy");
          this.label2 = this.$t("modal.discount");
          this.label3 = "%";
          break;
        case 4:
          this.label1 = this.$t("modal.satisfy");
          this.label2 = this.$t("modal.sale");
          this.label3 = this.$t("modal.ntd");
          break;
        case 6:
        case 9:
          this.label1 = this.$t("modal.satisfy");
          this.label2 = this.$t("modal.freeprice");
          this.label3 = this.$t("modal.ntd");
          break;
        case 8:
          this.label1 = this.$t("modal.from");
          this.label2 = this.$t("modal.amount2");
          this.label3 = "%";
          break;
        case 10:
          this.label1 = this.$t("modal.greaterthan");
          this.label2 = this.$t("modal.amount2");
          this.label3 = "%";
          break;
        case 11:
          this.label1 = this.$t("modal.buy");
          this.label2 = this.$t("modal.present");
          this.label3 = this.$t("modal.amount");
          break;
      }
    },
    onSaved() {
      const vm = this;
      this.$api.promotionDets.save(vm.promotionDet).then(() => {
        vm.modalTrigger = !vm.modalTrigger;
        this.$emit("onSaved");
      });
    },
  },
};
</script>
