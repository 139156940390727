<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getMenus"
      @addItem="addMenu"
      @editItem="editMenu"
      @removeItem="removeMenu"
      :items="menus"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      @currentIndexChanged="currentIndexChanged"
      :tableName="componentId"
    >
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="showCategories"
        >
          <i class="fa fa-project-diagram"></i>
        </button>
      </template>
    </pageTable>

    <menu-data
      :modalTitle="modalTitle"
      :entity="menu"
      :isShow="isShowModal"
      @close="isShowModal = !isShowModal"
      @save="saveMenu"
    >
    </menu-data>

    <online-order-category-select
      :hasSequence="true"
      :trigger="categoryTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderCategories"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveCategories"
    ></online-order-category-select>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import menuData from "@/components/onlineOrders/OnlineOrderMenuData";
import onlineOrderCategorySelect from "@/components/selects/OnlineOrderCategorySelects";

export default {
  data() {
    return {
      menus: [],
      pagination: {},
      columns: [
        {
          name: "OnlineOrderMenuName",
        },
        {
          name: "DayModuleName",
        },
        {
          name: "OnlineOrderCategoryText",
          headerText: "類別",
        },
        {
          name: "EatTypeText",
        },
      ],
      idProperty: "OnlineOrderMenuId",
      nameProperty: "OnlineOrderMenuName",
      getTrigger: false,
      authority: {},
      menu: {},
      isShowModal: false,
      categoryTrigger: false,
      onlineOrderCategories: undefined,
      currentIndex: -1,
      modalTitle: "",
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    menuData,
    onlineOrderCategorySelect,
  },

  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getMenus(dTO) {
      const vm = this;
      this.$api.onlineOrderMenus
        .getPages(this.onlineOrderType, dTO)
        .then((data) => {
          vm.menus = data.Datas;
          vm.menus.forEach((menu) => {
            menu.EatTypeText = vm.$enum.eatType.getByValue(menu.EatType).text;
          });
          vm.pagination = data.Pagination;
        });
    },
    addMenu() {
      this.menu = this.$func.defaultModel.getOnlineOrderMenu(
        this.onlineOrderType
      );
      this.menu.EatType = 2;
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addmenu");
    },
    editMenu(menu) {
      const vm = this;
      this.$api.onlineOrderMenus.get(menu.OnlineOrderMenuId).then((data) => {
        vm.menu = data;
        vm.isShowModal = !vm.isShowModal;
        this.modalTitle = this.$t("modal.editmenu");
      });
    },
    saveMenu() {
      this.getTrigger = !this.getTrigger;
      this.isShowModal = !this.isShowModal;
    },
    removeMenu(menu) {
      const vm = this;
      this.$api.onlineOrderMenus.remove(menu.OnlineOrderMenuId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    showCategories() {
      const menu = this.menus[this.currentIndex];
      if (!menu) {
        this.$func.message.alert("請選擇菜單 !");
        return;
      }
      this.onlineOrderCategories = undefined;
      const vm = this;
      this.$api.onlineOrderMenus
        .getCategories(menu.OnlineOrderMenuId)
        .then((data) => {
          vm.onlineOrderCategories = data;
          vm.categoryTrigger = !vm.categoryTrigger;
        });
    },
    saveCategories(items) {
      const menu = this.menus[this.currentIndex];
      if (!menu) {
        this.$func.message.alert("請選擇菜單 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderMenus
        .postCategories(
          menu.OnlineOrderMenuId,
          items.map((item) => item.OnlineOrderCategoryId)
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
