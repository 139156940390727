<template>
  <div>
    <div class="card">
      <div class="card-header">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon"
          v-if="!noSearch"
          @click="getItems()"
        >
          <i class="fas fa-search"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-success btn-icon"
          v-if="hasExcel"
          @click="downloadExcel"
        >
          <i class="fas fa-file-excel"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-danger btn-icon"
          v-if="hasPdf"
          @click="downloadPdf"
        >
          <i class="fas fa-file-pdf"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          v-if="hasPdf"
          @click="printPdf"
        >
          <i class="fas fa-print"></i>
        </button>

        <slot name="searchButtons"></slot>
      </div>
      <div class="card-body">
        <div
          v-if="reportTypes"
          class="btn-group mb-3"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class="btn btn-outline-secondary"
            v-for="type in reportTypes"
            :key="type"
            :class="{ active: type == condition.ReportType }"
            @click="changeReportType(type)"
          >
            {{ getReportTypeName(type) }}
          </button>
        </div>

        <div class="row">
          <date-range-part
            v-if="hasDate"
            :date="condition"
            :gridDegree="8"
            :degree="12"
            @dateChanged="dateChanged"
          />
          <multi-date-range-part
            v-if="hasMultiDate"
            :date="condition"
            :gridDegree="8"
            :degree="12"
            :hasDeliveryDate="hasDeliveryDate"
            :hasAccountDate="hasAccountDate"
            @multiDateChanged="multiDateChanged"
            @dateSourceTypeChanged="dateSourceTypeChanged"
          />
          <input-inline-part
            id="endDate"
            :labelText="`${$t('modal.date')}:`"
            v-if="hasEndDate"
            :gridDegree="8"
            :degree="12"
          >
            <input
              id="endDate"
              type="date"
              class="form-control"
              v-model="condition.DayEnd"
            />
          </input-inline-part>
          <input-inline-part
            id="businessClass"
            :labelText="`${$t('modal.businessclass')}:`"
            v-if="hasBusinessClass"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="hasBusinessClass"
              class="form-control"
              v-model="condition.BusinessClass"
            >
              <option
                v-for="type in businessClassTypes"
                :key="type.value"
                :value="type.value"
              >
                {{ $t(`option.businessClassType.${type.name}`) }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="isShowAll"
            :labelText="`${$t('modal.isshowall')}:`"
            v-if="isShowAll && condition.ReportType == 'simple'"
            :gridDegree="8"
            :degree="12"
          >
            <input
              type="radio"
              id="isShow"
              class="mr-3"
              name="isShowAll"
              value="1"
              v-model="isShowAllValue"
            />
            <label class="col-form-label" for="isShow">{{
              $t("option.yesOrNo.yes")
            }}</label>
            <input
              type="radio"
              id="isNotShow"
              class="ml-5 mr-3"
              name="isShowAll"
              value="0"
              v-model="isShowAllValue"
            />
            <label class="col-form-label" for="isNotShow">{{
              $t("option.yesOrNo.no")
            }}</label>
          </input-inline-part>

          <input-inline-part
            id="tradeStatusType"
            :labelText="`${$t('modal.status')}:`"
            v-if="hasTradeStatusType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="tradeStatusType"
              class="form-control"
              v-model="condition.TradeStatusType"
            >
              <option
                v-for="tradeStatusType in tradeStatusTypes"
                :key="tradeStatusType.value"
                :value="tradeStatusType.value"
              >
                {{ tradeStatusType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="foodStatusType"
            :labelText="`${$t('modal.foodstatus')}:`"
            v-if="hasFoodStatusType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="foodStatusType"
              class="form-control"
              v-model="condition.FoodStatusType"
            >
              <option
                v-for="foodStatusType in foodStatusTypes"
                :key="foodStatusType.value"
                :value="foodStatusType.value"
              >
                {{ foodStatusType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="reportSourceType"
            :labelText="`${$t('modal.reportsourcetype')}:`"
            v-if="hasReportSourceType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="reportSourceType"
              class="form-control"
              v-model="condition.SourceType"
            >
              <option
                v-for="reportSourceType in reportSourceTypes"
                :key="reportSourceType.value"
                :value="reportSourceType.value"
              >
                {{ reportSourceType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="purchaseOrderStatusType"
            :labelText="`${$t('modal.status')}:`"
            v-if="isPurchaseOrder"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="purchaseOrderStatusType"
              class="form-control"
              v-model="condition.TradeStatusType"
            >
              <option
                v-for="purchaseOrderStatusType in purchaseOrderStatusTypes"
                :key="purchaseOrderStatusType.value"
                :value="purchaseOrderStatusType.value"
              >
                {{ purchaseOrderStatusType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="timeType"
            :labelText="`${$t('modal.timetype')}:`"
            v-if="hasTimeType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="timeType"
              class="form-control"
              v-model="condition.TimeReportType"
            >
              <option
                v-for="timeType in timeTypes"
                :key="timeType.value"
                :value="timeType.value"
              >
                {{ timeType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="rankType"
            :labelText="`${$t('modal.ranktype')}:`"
            v-if="hasRankType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="rankType"
              class="form-control"
              v-model="condition.RankType"
            >
              <option
                v-for="rankType in rankTypes"
                :key="rankType.value"
                :value="rankType.value"
              >
                {{ rankType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="invoicePeriod"
            :labelText="`${$t('modal.period')}:`"
            v-if="hasInvoicePeriod"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="invoicePeriod"
              class="form-control"
              v-model="condition.Period"
            >
              <option
                v-for="invoicePeriod in invoicePeriods"
                :key="invoicePeriod.value"
                :value="invoicePeriod.text"
              >
                {{ invoicePeriod.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="salPriceSeperate"
            :labelText="`${$t('modal.salepriceseperate')}:`"
            v-if="hasSalPriceSeperate"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="salPriceSeperate"
              class="form-control"
              v-model="condition.IsSalPriceSeperate"
            >
              <option value="false">{{ $t("modal.combine") }}</option>
              <option value="true">{{ $t("modal.seperate") }}</option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="costType"
            :labelText="`${$t('modal.costtype')}:`"
            v-if="hasCostType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="costType"
              class="form-control"
              v-model="condition.CostType"
            >
              <option
                v-for="costType in costTypes"
                :key="costType.value"
                :value="costType.value"
              >
                {{ costType.text }}
              </option>
            </select>
          </input-inline-part>

          <input-inline-part
            id="batchNumber"
            :labelText="`${$t('modal.batchnumber')}:`"
            v-if="hasBatchNumber"
            :gridDegree="12"
            :degree="12"
          >
            <div class="row">
              <div class="col">
                <input
                  id="batchNumberBegin"
                  type="number"
                  class="form-control"
                  v-model="condition.BatchNumberBegin"
                />
              </div>
              <label
                for="batchNumberBegin"
                class="col-1 col-form-label d-flex justify-content-center align-items-center"
                >~</label
              >
              <div class="col">
                <input
                  type="batchNumberEnd"
                  class="form-control"
                  v-model="condition.BatchNumberEnd"
                />
              </div>
            </div>
          </input-inline-part>

          <input-inline-part
            id="qtyType"
            :labelText="`${$t('modal.qty')}:`"
            v-if="hasQtyType"
            :gridDegree="8"
            :degree="12"
          >
            <select
              id="qtyType"
              class="form-control"
              v-model="condition.QtyType"
            >
              <option
                v-for="qty in qtyTypes"
                :key="qty.value"
                :value="qty.value"
              >
                {{ qty.text }}
              </option>
            </select>
          </input-inline-part>

          <div
            id="eatType"
            class="eat-type-selector form-group row col-12"
            v-if="hasEatType"
          >
            <label for="eatType" class="col-3 col-form-label col-sm-2 col-lg-1"
              >{{ $t("modal.eattype") }}:</label
            >
            <div class="row col d-flex align-items-center eat-type-options">
              <div class="d-flex align-items-center eat-type-option">
                <input
                  id="eatTypeAll"
                  type="checkbox"
                  class="checkbox eat-type-checkbox"
                  v-model="allSelected"
                  @change="changeAllSelected"
                />
                <label for="eatTypeAll" class="eat-type-text">{{
                  $t("modal.all")
                }}</label>
              </div>
              <div
                class="d-flex align-items-center eat-type-option"
                v-for="eatType in eatTypeCheckboxes"
                :key="eatType.value"
              >
                <input
                  :id="eatType.name"
                  type="checkbox"
                  class="checkbox eat-type-checkbox"
                  :value="eatType"
                  v-model="selectedEatTypes"
                />
                <label :for="eatType.name" class="eat-type-text">{{
                  eatType.text
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <store-search
          v-if="hasStore"
          @set="storeChanged"
          :searchItems="searchItems"
        />
        <terminal-search
          v-if="hasTerminal"
          @set="terminalChanged"
          :searchItems="searchItems"
        />
        <employee-search
          v-if="hasEmployee"
          @set="employeeChanged"
          :searchItems="searchItems"
        />
        <saler-search
          v-if="hasSaler"
          @set="salerChanged"
          :searchItems="searchItems"
        />
        <buyer-search
          v-if="hasBuyer"
          @set="buyerChanged"
          :searchItems="searchItems"
        />
        <supplier-search
          v-if="hasSupplier"
          @set="supplierChanged"
          :searchItems="searchItems"
        />
        <food-supplier-search
          v-if="hasFoodSupplier"
          @set="foodSupplierChanged"
          :searchItems="searchItems"
        />
        <customer-search
          v-if="hasCustomer"
          @set="customerChanged"
          :searchItems="searchItems"
        />
        <warehouse-search
          v-if="hasWarehouse"
          @set="warehouseChanged"
          :searchItems="searchItems"
        />
        <food-major-kind-search
          v-if="hasFoodMajorKind"
          @set="foodMajorKindChanged"
          :searchItems="searchItems"
        />
        <food-kind-search
          v-if="hasFoodKind"
          @set="foodKindChanged"
          :searchItems="searchItems"
        />
        <food-search
          v-if="hasFood"
          @set="foodChanged"
          :searchItems="searchItems"
        />
        <directory-search
          v-if="hasDirectory"
          @set="directoryChanged"
          :searchItems="searchItems"
        />

        <slot name="searchArea" />
      </div>
    </div>

    <slot name="middleArea"></slot>

    <page-table
      v-if="columns"
      v-show="!noPage"
      :hasAdd="hasAdd"
      :hasEdit="hasEdit"
      :hasRemove="hasRemove"
      :items="items"
      :parentPage="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="addItem"
      @editItem="editItem"
      @removeItem="removeItem"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      @trdbClicked="trdbClicked"
      :noInitialLoading="noInitialLoading"
      :tableName="tableName"
      :noInitialTableName="reportTypes && reportTypes.length > 0"
      :isSortable="isSortable"
      :hasTotal="hasTotal"
    >
      <template slot="button" slot-scope="props">
        <slot name="dataButtons" :index="props.index" :item="props.item"></slot>
      </template>

      <template slot="th-before">
        <slot name="th-before" />
      </template>
      <template slot="th-after">
        <slot name="th-after" />
      </template>
      <template slot="td-before" slot-scope="props">
        <slot name="td-before" :item="props.item" />
      </template>
      <template slot="td-after" slot-scope="props">
        <slot name="td-after" :item="props.item" />
      </template>
    </page-table>
  </div>
</template>

<script>
import dateRangePart from "@/components/parts/dateRangePart";
import multiDateRangePart from "@/components/parts/multiDateRangePart";
import inputInlinePart from "@/components/parts/inputInlinePart";

import storeSearch from "@/components/searchs/StoreSearch";
import employeeSearch from "@/components/searchs/EmployeeSearch";
import salerSearch from "@/components/searchs/SalerSearch";
import buyerSearch from "@/components/searchs/BuyerSearch";
import customerSearch from "@/components/searchs/CustomerSearch";
import supplierSearch from "@/components/searchs/SupplierSearch";
import foodSupplierSearch from "@/components/searchs/FoodSupplierSearch";
import warehouseSearch from "@/components/searchs/WarehouseSearch";
import foodMajorKindSearch from "@/components/searchs/FoodMajorKindSearch";
import foodKindSearch from "@/components/searchs/FoodKindSearch";
import foodSearch from "@/components/searchs/FoodSearch";
import terminalSearch from "@/components/searchs/TerminalSearch";
import directorySearch from "@/components/searchs/DirectorySearch";

import pageTable from "@/components/PageTable";

import date from "@/filters/date";

export default {
  data() {
    return {
      invoicePeriods: [
        {
          value: 1,
          text: undefined,
        },
        {
          value: 2,
          text: undefined,
        },
        {
          value: 3,
          text: undefined,
        },
        {
          value: 4,
          text: undefined,
        },
        {
          value: 5,
          text: undefined,
        },
        {
          value: 6,
          text: undefined,
        },
      ],
      businessClassTypes: [],
      tradeStatusTypes: [],
      reportSourceTypes: [],
      purchaseOrderStatusTypes: [],
      eatTypes: [],
      timeTypes: [],
      rankTypes: [],
      costTypes: [],
      foodStatusTypes: [],
      qtyTypes: [],
      condition: {
        DayBegin: date(Date.now()),
        DayEnd: date(Date.now()),
        Page: 1,
        PageSize: 25,
        ReportType: "",
      },
      allSelected: true,
      selectedEatTypes: [],
      isShowAllValue: 0,
      searchItems: {},
      tableName: null,
    };
  },
  props: [
    "isReturn",
    "hasExcel",
    "hasPdf",
    "hasAdd",
    "hasEdit",
    "hasRemove",
    "hasDate",
    "hasDeliveryDate",
    "hasAccountDate",
    "hasEndDate",
    "hasStore",
    "hasEmployee",
    "hasSaler",
    "hasBuyer",
    "hasSupplier",
    "hasFoodSupplier",
    "hasCustomer",
    "hasWarehouse",
    "hasFoodMajorKind",
    "hasFoodKind",
    "hasFood",
    "hasTerminal",
    "hasTradeStatusType",
    "hasEatType",
    "hasTimeType",
    "hasRankType",
    "reportTypes",
    "columns",
    "items",
    "pagination",
    "nameProperty",
    "getTrigger",
    "noPage",
    "noSearch",
    "isPurchaseOrder",
    "noInitialLoading",
    "isSortable",
    "hasInvoicePeriod",
    "hasSalPriceSeperate",
    "hasDirectory",
    "hasBusinessClass",
    "hasCostType",
    "isShowAll",
    "hasReportSourceType",
    "hasFoodStatusType",
    "hasBatchNumber",
    "hasQtyType",
    "hasTotal",
  ],
  components: {
    dateRangePart,
    multiDateRangePart,
    inputInlinePart,
    storeSearch,
    employeeSearch,
    salerSearch,
    buyerSearch,
    supplierSearch,
    foodSupplierSearch,
    customerSearch,
    warehouseSearch,
    foodMajorKindSearch,
    foodKindSearch,
    foodSearch,
    terminalSearch,
    directorySearch,
    pageTable,
  },
  computed: {
    hasMultiDate() {
      return this.hasDeliveryDate || this.hasAccountDate;
    },
    eatTypeCheckboxes() {
      const types = this.eatTypes.filter((eatType) => {
        return (
          eatType.value == 0 ||
          eatType.value == 1 ||
          eatType.value == 2 ||
          eatType.value == 6
        );
      });
      return types;
    },
    currentEatType() {
      if (this.selectedEatTypes.length == this.eatTypeCheckboxes.length) {
        return -1;
      } else {
        const vm = this;
        const match = this.eatTypes.find((eatType) => {
          const allMatch = vm.selectedEatTypes.every((type) => {
            return eatType.name.toLowerCase().includes(type.name.toLowerCase());
          });
          return allMatch;
        });
        return match.value;
      }
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    columns() {
      this.reNamePayType();
    },
    items(value) {
      if (value.length == 0 && this.condition.Page > 1) {
        let pageDTO = {
          isDesc: this.condition.IsDesc,
          page: this.condition.Page - 1,
          pagesize: this.condition.PageSize,
          sortName: this.condition.SortName,
        };
        this.getItems(pageDTO);
      }
    },
    currentLang() {
      this.setOptions();
    },
    selectedEatTypes(value) {
      if (value.length == this.eatTypeCheckboxes.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    isReturn: {
      handler(value) {
        this.condition.IsReturn = value;
      },
      immediate: true,
    },
    reportTypes: {
      handler(items) {
        if (items && items.length > 0) {
          this.condition.ReportType = items[0];
        }
      },
      immediate: true,
    },
    hasTradeStatusType: {
      handler() {
        this.condition.TradeStatusType = 0;
      },
      immediate: true,
    },
    hasReportSourceType: {
      handler() {
        this.condition.SourceType = -1;
      },
      immediate: true,
    },
    hasEatType: {
      handler() {
        this.condition.EatType = -1;
      },
      immediate: true,
    },
    hasTimeType: {
      handler() {
        this.condition.TimeReportType = 0;
      },
      immediate: true,
    },
    hasSalPriceSeperate: {
      handler() {
        this.condition.IsSalPriceSeperate = false;
      },
      immediate: true,
    },
    hasRankType: {
      handler() {
        this.condition.RankType = 0;
      },
      immediate: true,
    },
    hasBusinessClass: {
      handler() {
        this.condition.BusinessClass = 0;
      },
      immediate: true,
    },
    hasCostType: {
      handler() {
        this.condition.CostType = 1;
      },
      immediate: true,
    },
    hasFoodStatusType: {
      handler() {
        this.condition.FoodStatusType = -1;
      },
      immediate: true,
    },
    hasMultiDate: {
      handler() {
        this.condition.DateSourceType = 0;
      },
      immediate: true,
    },
    hasBatchNumber: {
      handler() {
        this.condition.BatchNumberBegin = "";
        this.condition.BatchNumberEnd = "";
      },
      immediate: true,
    },
  },
  methods: {
    setOptions() {
      this.businessClassTypes = this.$enum.businessClassType.getAll();
      this.tradeStatusTypes = this.$enum.tradeStatusType.getAll();
      this.reportSourceTypes = this.$enum.reportSourceType.getAll();
      this.purchaseOrderStatusTypes =
        this.$enum.purchaseOrderStatusType.getAll();
      this.eatTypes = this.$enum.eatSearchType.getAll();
      this.timeTypes = this.$enum.timeType.getAll();
      this.rankTypes = this.$enum.rankType.getAll();
      this.costTypes = this.$enum.costType.getAll();
      this.costTypes.splice(2, 1);
      this.foodStatusTypes = this.$enum.foodStatusType.getAll();
      this.qtyTypes = this.$enum.qtyType.getAll();
    },
    changeReportType(type) {
      if (type != this.condition.ReportType) {
        this.condition.ReportType = type;
        this.getItems();
      }
    },
    changeAllSelected() {
      if (this.allSelected) {
        this.selectedEatTypes = this.eatTypeCheckboxes;
      } else {
        this.selectedEatTypes = [];
      }
    },
    getReportTypeName(value) {
      return this.$t(`button.${value}`);
    },
    getItems(dTO) {
      if (!dTO) {
        this.condition.Page = 1;
      } else {
        this.condition.Page = dTO.page;
      }
      if (dTO) {
        this.condition.PageSize = dTO.pagesize;
        this.condition.SortName = dTO.sortName;
        this.condition.IsDesc = dTO.isDesc;
      }
      this.condition.EatType = this.currentEatType;
      if (this.isShowAllValue == 1) {
        this.condition.IsShowAll = true;
      } else if (this.isShowAllValue == 0) {
        this.condition.IsShowAll = false;
      }

      if (sessionStorage.getItem("isSamePage")) {
        this.condition = JSON.parse(sessionStorage.getItem("condition"));
        this.searchItems = JSON.parse(sessionStorage.getItem("searchItems"));
        this.$nextTick(() => sessionStorage.removeItem("isSamePage"));
      } else {
        sessionStorage.setItem("condition", JSON.stringify(this.condition));
        sessionStorage.setItem("searchItems", JSON.stringify(this.searchItems));
        sessionStorage.setItem("routeName", this.$route.name);
      }
      this.tableName = this.condition.ReportType;

      if (!this.hasCostType) {
        this.condition.CostType = 0;
      }
      this.$emit("getItems", this.condition);
    },
    downloadExcel() {
      this.$emit("downloadExcel", this.condition);
    },
    downloadPdf() {
      this.$emit("downloadPdf", this.condition);
    },
    printPdf() {
      this.$emit("printPdf", this.condition);
    },
    dateChanged(begin, end) {
      this.condition.DayBegin = begin;
      this.condition.DayEnd = end;
    },
    multiDateChanged(begin, end, type) {
      this.condition.DayBegin = begin;
      this.condition.DayEnd = end;
      this.condition.DateSourceType = type;
    },
    dateSourceTypeChanged(type) {
      this.condition.DateSourceType = type;
    },
    storeChanged(items) {
      this.searchItems.Store = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.StoreId);
      });
      this.condition.StoreIds = ids;
    },
    employeeChanged(items) {
      this.searchItems.Employee = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.EmployeeId);
      });
      this.condition.EmployeeIds = ids;
    },
    salerChanged(items) {
      this.searchItems.Saler = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.EmployeeId);
      });
      this.condition.SalerIds = ids;
    },
    buyerChanged(items) {
      this.searchItems.Buyer = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.EmployeeId);
      });
      this.condition.BuyerIds = ids;
    },
    supplierChanged(items) {
      this.searchItems.Supplier = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.SupplierId);
      });
      this.condition.SupplierIds = ids;
    },
    foodSupplierChanged(items) {
      this.searchItems.FoodSupplier = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.SupplierId);
      });
      this.condition.FoodSupplierIds = ids;
    },
    customerChanged(items) {
      this.searchItems.Customer = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.CustomerId);
      });
      this.condition.CustomerIds = ids;
    },
    warehouseChanged(items) {
      this.searchItems.Warehouse = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.WarehouseId);
      });
      this.condition.WarehouseIds = ids;
    },
    foodMajorKindChanged(items) {
      this.searchItems.FoodMajorKind = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.FoodMajorKindId);
      });
      this.condition.FoodMajorKindIds = ids;
    },
    foodKindChanged(items) {
      this.searchItems.FoodKind = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.FoodKindId);
      });
      this.condition.FoodKindIds = ids;
    },
    foodChanged(items) {
      this.searchItems.Food = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.FoodId);
      });
      this.condition.FoodIds = ids;
    },
    terminalChanged(items) {
      this.searchItems.Terminal = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.TerminalId);
      });
      this.condition.TerminalIds = ids;
    },
    directoryChanged(items) {
      this.searchItems.Directory = items;
      let ids = [];
      items.forEach((item) => {
        ids.push(item.DirectoryId);
      });
      this.condition.DirectoryIds = ids;
    },
    addItem() {
      this.$emit("addItem");
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    removeItem(item) {
      this.$emit("removeItem", item);
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
    reNamePayType() {
      if (this.columns) {
        this.columns.forEach((column) => {
          if (column.name == "PayType1") {
            column.headerText = JSON.parse(localStorage.getItem("payType1"));
          } else if (column.name == "PayType2") {
            column.headerText = JSON.parse(localStorage.getItem("payType2"));
          }
        });
      }
    },
  },
  created() {
    if (this.$route.name == sessionStorage.getItem("routeName")) {
      sessionStorage.setItem("isSamePage", true);
    } else {
      sessionStorage.removeItem("searchItems");
    }
    const n = new Date();
    let year = n.getFullYear();
    let month = n.getMonth() + 1;
    const mod = month % 2;
    if (mod !== 0) {
      month += 1;
    }
    for (let i = 0; i < this.invoicePeriods.length; i++) {
      if (month - 2 * this.invoicePeriods[i].value > 0) {
        if (month - 2 * this.invoicePeriods[i].value < 10) {
          this.invoicePeriods[i].text = `${year - 1911}0${
            month - 2 * this.invoicePeriods[i].value
          }`;
        } else {
          this.invoicePeriods[i].text = `${year - 1911}${
            month - 2 * this.invoicePeriods[i].value
          }`;
        }
      } else {
        if (month - 2 * this.invoicePeriods[i].value + 12 < 10) {
          this.invoicePeriods[i].text = `${year - 1912}0${
            month - 2 * this.invoicePeriods[i].value + 12
          }`;
        } else {
          this.invoicePeriods[i].text = `${year - 1912}${
            month - 2 * this.invoicePeriods[i].value + 12
          }`;
        }
      }
    }
    if (this.hasInvoicePeriod) {
      this.condition.Period = this.invoicePeriods[0].text;
    }
  },
  mounted() {
    this.setOptions();
    this.selectedEatTypes = this.eatTypeCheckboxes;
    this.reNamePayType();
  },
};
</script>
