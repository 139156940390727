<template>
  <form class="card" @submit.prevent="onSaved">
    <div class="card-header">
      <button
        type="button"
        class="btn btn-outline-primary btn-icon"
        @click="onSaved"
      >
        <i class="fas fa-check"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-icon"
        @click="onClosed"
      >
        <i class="fas fa-times"></i>
      </button>
      <import-part @onImport="onImport" :title="`${$t('modal.importfood')}`" />
      <export-part @onExport="onExport" />
    </div>
    <div class="card-body">
      <div class="row">
        <input-part id="purchaseDate" :labelText="`${$t('modal.date')}:`">
          <input
            type="date"
            class="form-control"
            id="purchaseDate"
            v-model="purchase.PurchaseDate"
            required
          />
        </input-part>
        <input-part
          id="purchaseNumber"
          :labelText="`${$t('modal.purchaseordernumber')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="purchaseNumber"
            v-model="purchase.PurchaseNumber"
            readonly
          />
        </input-part>
        <input-part id="supplier" :labelText="`${$t('modal.supplier')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierNumber"
              :placeholder="`${$t('placeholder.inputsuppliernumberordb')}`"
              v-model="addSupplierNumber"
              @keyup.enter.exact="selectSupplierByKeyword"
              @dblclick="supplierModalTrigger = !supplierModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="supplierName"
              readonly
              :value="purchase.SupplierName"
            />
          </div>
        </input-part>
        <input-part id="buyer" :labelText="`${$t('modal.buyer')}:`">
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="buyerNumber"
              :placeholder="`${$t('placeholder.inputbuyernumberordb')}`"
              v-model="addBuyerNumber"
              @keyup.enter.exact="selectBuyerByKeyword"
              @dblclick="buyerModalTrigger = !buyerModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="buyerName"
              readonly
              :value="purchase.BuyerName"
            />
          </div>
        </input-part>
        <input-part
          id="defaultWarehouse"
          :labelText="`*${$t('modal.defaultwarehouse')}:`"
        >
          <div class="row px-3">
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseNumber"
              :placeholder="`${$t('placeholder.inputwarehousenumberordb')}`"
              v-model="addWarehouseNumber"
              @keyup.enter.exact="selectWarehouseByKeyword"
              @dblclick="warehouseModalTrigger = !warehouseModalTrigger"
            />
            <input
              type="text"
              class="form-control col-12 col-md-6"
              id="warehouseName"
              readonly
              :value="warehouse.WarehouseName"
            />
          </div>
        </input-part>
        <input-part
          id="contactPerson"
          :labelText="`${$t('modal.contactperson')}:`"
        >
          <input
            type="text"
            class="form-control"
            id="contactPerson"
            v-model="purchase.ContactPerson"
          />
        </input-part>
        <input-part id="telephone" :labelText="`${$t('modal.telephone')}:`">
          <input
            type="text"
            class="form-control"
            id="telephone"
            v-model="purchase.Telephone"
          />
        </input-part>
        <input-part id="faxNumber" :labelText="`${$t('modal.faxnumber')}:`">
          <input
            type="text"
            class="form-control"
            id="faxNumber"
            v-model="purchase.FaxNumber"
          />
        </input-part>
        <input-part id="memo" :labelText="`${$t('modal.memo')}:`" :degree="12">
          <input
            type="text"
            class="form-control"
            id="memo"
            v-model="purchase.Memo"
          />
        </input-part>
        <input-part id="taxType" :labelText="`${$t('modal.taxtype')}:`">
          <div class="row">
            <div class="col-9">
              <select
                class="form-control"
                id="taxType"
                v-model="purchase.TaxType"
                required
              >
                <option
                  v-for="taxType in taxTypes"
                  :key="taxType.value"
                  :value="taxType.value"
                >
                  {{ $t(`option.taxType.${taxType.name}`) }}
                </option>
              </select>
            </div>
            <div class="col-3 custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="IsTaxAddition"
                v-model="purchase.IsTaxAddition"
              />
              <label class="custom-control-label" for="IsTaxAddition">{{
                $t("modal.istaxaddition")
              }}</label>
            </div>
          </div>
        </input-part>
        <div class="col-12">
          <button
            class="btn btn-outline-primary mb-3"
            type="button"
            @click="getOutOfSafeQty"
          >
            {{ $t("button.getoutofsafeqty") }}
          </button>
        </div>
        <div class="col-12">
          <table-part
            :columns="columns"
            @setDetsMemo="setDetsMemo"
            :noHide="true"
          >
            <template slot="th-before">
              <th style="width: 80px">{{ $t("columnName.remove") }}</th>
              <th style="width: 80px">{{ $t("columnName.index") }}</th>
            </template>
            <tbody slot="tbody">
              <tr v-for="(det, index) in purchaseDets" :key="det.PurchaseDetId">
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="removeDet(index)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ det.FoodNumber }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.FoodName"
                    @focus="saveDefaultName(det.FoodName)"
                    @blur="checkNewName(det)"
                    required
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.WarehouseNumber"
                    @change="updateWarehouse(det)"
                    required
                  />
                </td>
                <td>{{ det.WarehouseName }}</td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.Qty"
                    @input="calculate"
                    @blur="setValue(det, 'Qty')"
                    required
                  />
                </td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.OriginalPrice"
                    @input="calculate"
                    @blur="setValue(det, 'OriginalPrice')"
                    required
                  />
                </td>
                <td class="text-right">{{ det.SalPrice }}</td>
                <td>
                  <input
                    class="form-control text-right"
                    type="number"
                    v-model.number="det.Total"
                    @input="reCalPrice(det)"
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                    required
                  />
                </td>
                <td class="text-right">{{ det.TaxPrice }}</td>
                <td>
                  <input class="form-control" type="text" v-model="det.Memo" />
                </td>
                <td>{{ det.Barcode }}</td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="det.RetailPrice"
                    :readonly="!isPurchaseUpdateRetailPrice"
                  />
                </td>
                <td>{{ det.Unit }}</td>
              </tr>
              <tr>
                <td colspan="16">
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="`${$t('placeholder.inputfoodnumberordb')}`"
                    v-model="addFoodNumber"
                    @keyup.enter.exact="addByNumber"
                    @dblclick="foodModalTrigger = !foodModalTrigger"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("columnName.total") }}:</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totalQty }}</td>
                <td></td>
                <td></td>
                <td class="text-right">{{ purchase.Total }}</td>
                <td class="text-right">{{ purchase.TaxPrice }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table-part>
        </div>
      </div>
      <food-selects
        :trigger="foodModalTrigger"
        :isMultiple="true"
        @onSaved="addFoods"
        :parentItems="foodsByKeyword"
        :noSearchType="!hasFoodSearchType"
      ></food-selects>
      <supplier-selects
        :trigger="supplierModalTrigger"
        :isMultiple="false"
        @onSaved="setSupplier"
      ></supplier-selects>
      <warehouse-selects
        :trigger="warehouseModalTrigger"
        :isMultiple="false"
        @onSaved="setDefaultWarehouse"
      ></warehouse-selects>
      <employee-selects
        :trigger="buyerModalTrigger"
        :isMultiple="false"
        @onSaved="setBuyer"
      ></employee-selects>
    </div>
  </form>
</template>

<script>
import inputPart from "@/components/parts/inputPart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import tablePart from "@/components/parts/tablePart";

import foodSelects from "@/components/selects/FoodSelects";
import supplierSelects from "@/components/selects/SupplierSelects";
import warehouseSelects from "@/components/selects/WarehouseSelects";
import employeeSelects from "@/components/selects/EmployeeSelects";

export default {
  data() {
    return {
      taxTypes: this.$enum.taxType.getAll(),
      purchase: this.$func.defaultModel.getPurchase(),
      purchaseDets: [],
      addFoodNumber: "",
      addSupplierNumber: "",
      addBuyerNumber: "",
      addWarehouseNumber: "",
      foodModalTrigger: false,
      supplierModalTrigger: false,
      warehouseModalTrigger: false,
      buyerModalTrigger: false,
      warehouse: {
        WarehouseName: null,
      },
      roundType: 0,
      detailRoundType: 0,
      isFocused: false,
      hasFoodSearchType: true,
      foodsByKeyword: [],
      currentDetsMemo: { value: 0, text: "自訂" },
      isPurchaseUpdateRetailPrice: false,
    };
  },
  watch: {
    "purchase.TaxType"(value) {
      if (value != 1) this.purchase.IsTaxAddition = 0;
      this.calculate();
    },
    "purchase.IsTaxAddition"() {
      this.calculate();
    },
  },
  computed: {
    totalQty() {
      if (this.purchaseDets.length > 0)
        return this.purchaseDets
          .map((item) => {
            return item.Qty;
          })
          .reduce((t, q) => {
            return parseInt(t) + parseInt(q);
          });
      else return 0;
    },
    columns() {
      return [
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "Qty" },
        { name: "OriginalPrice" },
        { name: "SalPrice", headerText: "平均單價" },
        { name: "Total" },
        { name: "TaxPrice" },
        { name: "Memo", hasDropDown: true },
        { name: "Barcode" },
        { name: "RetailPrice" },
        { name: "Unit" },
      ];
    },
  },
  components: {
    inputPart,
    foodSelects,
    supplierSelects,
    warehouseSelects,
    employeeSelects,
    importPart,
    exportPart,
    tablePart,
  },
  methods: {
    setDetsMemo(item) {
      this.currentDetsMemo = item;
      if (item.value == 1) {
        this.purchaseDets.forEach((det) => {
          det.Memo = det.Barcode;
        });
      } else if (item.value == 2) {
        this.purchaseDets.forEach((det) => {
          det.Memo = det.Barcode2;
        });
      } else if (item.value == 3) {
        this.purchaseDets.forEach((det) => {
          det.Memo = det.Barcode3;
        });
      } else if (item.value == 4) {
        this.purchaseDets.forEach((det) => {
          det.Memo = det.FoodMemo;
        });
      }
    },
    setValue(det, type) {
      if (type == "Qty") {
        if (
          isNaN(det.Qty) ||
          det.Qty === "" ||
          det.Qty === null ||
          det.Qty == 0
        ) {
          this.$func.message.alert("數量不可為0 !");
          det.Qty = 1;
          this.calculate();
        }
      } else if (type == "OriginalPrice") {
        if (
          isNaN(det.OriginalPrice) ||
          det.OriginalPrice === "" ||
          det.OriginalPrice === null
        ) {
          det.OriginalPrice = 0;
        }
      }
    },
    selectSupplierByKeyword() {
      if (!this.addSupplierNumber || this.addSupplierNumber.length == 0) {
        this.$func.message.alert("請輸入廠商編號");
        return;
      }
      const vm = this;
      this.$api.suppliers
        .getByNumber(this.addSupplierNumber)
        .then((data) => {
          if (data) {
            vm.setSupplier(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此廠商 !");
          this.setSupplier({
            SupplierNumber: null,
            SupplierId: null,
            SupplierName: null,
            ContactPerson: null,
            Telephone: null,
            FaxNumber: null,
          });
        });
    },
    selectBuyerByKeyword() {
      if (!this.addBuyerNumber || this.addBuyerNumber.length == 0) {
        this.$func.message.alert("請輸入業務員編號");
        return;
      }
      const vm = this;
      this.$api.employees
        .getByNumber(this.addBuyerNumber)
        .then((data) => {
          if (data) {
            vm.setBuyer(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此業務員 !");
          this.setBuyer({
            EmployeeNumber: null,
            EmployeeId: null,
            EmployeeName: null,
            DefaultWarehouseId: null,
            DefaultWarehouseNumber: null,
            DefaultWarehouseName: null,
          });
        });
    },
    selectWarehouseByKeyword() {
      if (!this.addWarehouseNumber || this.addWarehouseNumber.length == 0) {
        this.$func.message.alert("請輸入倉庫編號");
        return;
      }
      const vm = this;
      this.$api.warehouses
        .getByNumber(this.addWarehouseNumber)
        .then((data) => {
          if (data) {
            vm.setDefaultWarehouse(data);
          }
        })
        .catch(() => {
          this.$func.message.error("查無此倉庫 !");
          this.setDefaultWarehouse({
            WarehouseId: null,
            WarehouseNumber: null,
            WarehouseName: null,
          });
        });
    },
    saveDefaultName(name) {
      this.defaultFoodName = name;
    },
    checkNewName(det) {
      if (!det.FoodName) {
        this.$func.message.alert("請輸入商品名稱");
        det.FoodName = this.defaultFoodName;
      }
    },
    addDet(item) {
      let det = {
        FoodId: item.FoodId,
        FoodNumber: item.FoodNumber,
        FoodName: item.FoodName,
        WarehouseName: "",
        Barcode: item.Barcode,
        Barcode2: item.Barcode2,
        Barcode3: item.Barcode3,
        FoodMemo: item.Memo,
        Memo: "",
        Qty: 1,
        OriginalPrice: item.Cost,
        RetailPrice: item.SalPrice1,
      };

      if (this.currentDetsMemo.value == 1) {
        det.Memo = det.Barcode;
      } else if (this.currentDetsMemo.value == 2) {
        det.Memo = det.Barcode2;
      } else if (this.currentDetsMemo.value == 3) {
        det.Memo = det.Barcode3;
      } else if (this.currentDetsMemo.value == 4) {
        det.Memo = det.FoodMemo;
      }
      if (this.warehouse.WarehouseName) {
        this.setWarehouse(det, this.warehouse);
      }
      this.purchaseDets.push(det);

      this.calculate();
    },
    addByNumber() {
      if (!this.addFoodNumber || this.addFoodNumber.length == 0) {
        this.$func.message.alert("請輸入商品編號");
        return;
      }
      const vm = this;
      this.$api.foods
        .getByKeyword({ IsMatterStock: true, Keyword: this.addFoodNumber })
        .then((data) => {
          if (data) {
            if (data.length > 1) {
              vm.foodsByKeyword = data;
              vm.hasFoodSearchType = false;
              vm.foodModalTrigger = !vm.foodModalTrigger;
            } else {
              vm.addDet(data[0]);
            }
          }
        })
        .catch(() => this.$func.message.error("查無此商品 !"));
    },
    removeDet(index) {
      this.purchaseDets.splice(index, 1);
      this.calculate();
    },
    onSaved() {
      if (!this.purchase.SupplierId || this.purchase.SupplierId.length == 0) {
        this.$func.message.alert("請選擇廠商 !");
        return;
      }
      if (!this.purchase.BuyerId || this.purchase.BuyerId.length == 0) {
        this.$func.message.alert("請選擇採購人 !");
        return;
      }
      if (this.purchaseDets.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const checkWarehouseId = this.purchaseDets.some((det) => {
        return !det.WarehouseId;
      });
      if (checkWarehouseId) {
        this.$func.message.alert("請選擇倉庫 !");
        return;
      }

      if (!Number.isInteger(this.purchase.Total)) {
        this.$func.message.alert("總計必須為整數 !");
        return;
      }

      this.purchaseDets.forEach((det) => {
        delete det.Barcode;
        delete det.Barcode2;
        delete det.Barcode3;
        delete det.FoodMemo;
      });
      this.purchase.EmployeeId = window.localStorage["userId"];
      this.purchase.TradeType =
        this.$enum.tradeType.getByName("PurchaseOrder").value;
      const vm = this;
      this.$api.purchaseOrders
        .post({
          Purchase: vm.purchase,
          PurchaseDets: vm.purchaseDets,
        })
        .then((data) => {
          if (vm.$route.name == "PurchaseOrderAdd") {
            vm.$func.message.success(`單號為${data.Purchase.PurchaseNumber}`);
          }
          this.$store.dispatch("setSaveOrClose", true);
          this.$router.push({ name: "PurchaseOrders" });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onClosed() {
      this.$store.dispatch("setSaveOrClose", true);
      this.$router.go(-1);
    },
    setSupplier(item) {
      this.addSupplierNumber = item.SupplierNumber;
      this.purchase.SupplierId = item.SupplierId;
      this.purchase.SupplierName = item.SupplierName;
      this.purchase.ContactPerson = item.ContactPerson;
      this.purchase.Telephone = item.Telephone;
      this.purchase.FaxNumber = item.FaxNumber;
    },
    setBuyer(item) {
      this.addBuyerNumber = item.EmployeeNumber;
      this.purchase.BuyerId = item.EmployeeId;
      this.purchase.BuyerName = item.EmployeeName;
      if (item.DefaultWarehouseId) {
        this.warehouse.WarehouseId = data.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = data.DefaultWarehouseName;
      }
      if (data.DefaultWarehouseId) {
        this.purchaseDets.forEach((det) => {
          if (!det.WarehouseId) {
            this.setWarehouse(det, this.warehouse);
          }
        });
      }
    },
    addFoods(items) {
      const vm = this;
      items.forEach(function (item) {
        vm.addDet(item);
      });
    },
    calculate() {
      this.$func.calculateTax(
        this.purchase,
        this.purchaseDets,
        this.roundType,
        this.detailRoundType,
        this.isFocused
      );
    },
    reCalPrice(det) {
      det.OriginalPrice = Math.ceil((det.Total / det.Qty) * 100) / 100;
      this.calculate();
    },
    onExport() {
      this.$api.purchaseOrders.getExcelByPost(this.purchaseDets);
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.purchaseOrders.postExcel(formData).then((data) => {
        if (data) {
          data.Items.forEach((item) => {
            vm.pushItem(item);
          });
          vm.calculate();
        }
      });
    },
    updateWarehouse(det) {
      this.$api.warehouses
        .getByNumber(det.WarehouseNumber)
        .then((data) => {
          if (data) {
            this.setWarehouse(det, data);
          } else {
            this.setWarehouse(det, undefined);
          }
        })
        .catch(() => {
          this.$func.message.alert("查無此資料 !");
          this.setWarehouse(det, undefined);
        });
    },
    setWarehouse(det, warehouse) {
      if (warehouse) {
        det.WarehouseId = warehouse.WarehouseId;
        det.WarehouseNumber = warehouse.WarehouseNumber;
        det.WarehouseName = warehouse.WarehouseName;
      } else {
        det.WarehouseId = undefined;
        det.WarehouseNumber = undefined;
        det.WarehouseName = undefined;
      }
    },
    setDefaultWarehouse(item) {
      this.addWarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseId = item.WarehouseId;
      this.warehouse.WarehouseNumber = item.WarehouseNumber;
      this.warehouse.WarehouseName = item.WarehouseName;
    },
    getOutOfSafeQty() {
      if (!this.purchase.SupplierId) {
        this.$func.message.alert("請選擇廠商 !");
        return;
      }
      let vm = this;
      this.$api.purchaseOrders
        .getOutOfSafeQty(this.purchase.SupplierId)
        .then((data) => {
          data.forEach((det) => {
            let index = vm.purchaseDets.findIndex((item) => {
              return item.FoodId == det.FoodId;
            });
            if (index >= 0) {
              vm.purchaseDets.splice(index, 1);
            }

            vm.purchaseDets.push(det);
          });
          vm.calculate();
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    const isSaveOrClose = this.$store.state.isSaveOrClose;
    if (isSaveOrClose) {
      next();
    } else {
      const result = confirm("資料尚未儲存，確定離開當前頁面嗎 ?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
  created() {
    const id = this.$route.params["id"];
    const vm = this;
    if (id) {
      this.$api.purchaseOrders
        .get(id)
        .then((data) => {
          vm.addSupplierNumber = data.Purchase.SupplierNumber;
          vm.purchase = data.Purchase;
          vm.purchaseDets = data.PurchaseDets;
          vm.calculate();
          vm.$api.employees.get(data.Purchase.BuyerId).then((item) => {
            vm.addBuyerNumber = item.EmployeeNumber;
            if (item.DefaultWarehouseNumber) {
              vm.addWarehouseNumber = item.DefaultWarehouseNumber;
              vm.warehouse.WarehouseId = item.DefaultWarehouseId;
              vm.warehouse.WarehouseNumber = item.DefaultWarehouseNumber;
              vm.warehouse.WarehouseName = item.DefaultWarehouseName;
            }
          });
        })
        .catch(() => {
          vm.$func.message.alert("查無此筆單據 !");
          vm.$router.go(-1);
        });
    } else {
      this.$api.employees.get(window.localStorage["userId"]).then((data) => {
        this.addBuyerNumber = data.EmployeeNumber;
        this.addWarehouseNumber = data.DefaultWarehouseNumber;
        this.purchase.EmployeeId = data.EmployeeId;
        this.purchase.EmployeeName = data.EmployeeName;
        this.purchase.BuyerId = data.EmployeeId;
        this.purchase.BuyerName = data.EmployeeName;
        this.warehouse.WarehouseId = data.DefaultWarehouseId;
        this.warehouse.WarehouseNumber = data.DefaultWarehouseNumber;
        this.warehouse.WarehouseName = data.DefaultWarehouseName;
      });
    }
    this.$api.businesses.getPart().then((data) => {
      vm.roundType = data.RoundType;
      vm.detailRoundType = data.DetailRoundType;
      vm.purchase.IsTaxAddition = data.IsTaxAddition;
      vm.isPurchaseUpdateRetailPrice = data.IsPurchaseUpdateRetailPrice;
    });
  },
};
</script>
