<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasWarehouse="true"
      :hasFoodSupplier="hasSupplier"
      :hasFoodStatusType="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :isShowAll="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail"],
      reportType: "simple",
    };
  },
  components: {
    conditionPart,
  },
  computed: {
    hasSupplier() {
      return this.reportType == "simple";
    },
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "TradeType" },
          { name: "TradeTime" },
          { name: "TradeNumber" },
          { name: "SalQty" },
          { name: "VoidSalQty" },
          { name: "PurchaseQty" },
          { name: "PurchaseReturnQty" },
          { name: "AdjustQty" },
          { name: "CombinationInQty" },
          { name: "CombinationOutQty" },
          { name: "SeperationInQty" },
          { name: "SeperationOutQty" },
          { name: "TransferInQty" },
          { name: "TransferOutQty" },
          { name: "TradeDate" },
        ];
      } else if (this.reportType == "simple") {
        return [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "InitialQty" },
          { name: "SalQty" },
          { name: "VoidSalQty" },
          { name: "PurchaseQty" },
          { name: "PurchaseReturnQty" },
          { name: "AdjustQty" },
          { name: "CombinationInQty" },
          { name: "CombinationOutQty" },
          { name: "SeperationInQty" },
          { name: "SeperationOutQty" },
          { name: "TransferInQty" },
          { name: "TransferOutQty" },
          { name: "LastQty" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "SalTotal" },
          { name: "PurchaseTotal" },
          { name: "ActualSaleQty" },
        ];
      } else return undefined;
    },
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getFoodMovement(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getFoodMovement(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printFoodMovement(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getFoodMovement(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
