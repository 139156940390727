<template>
  <div class="row">
    <div class="col-6">
      <pageTable
        :parentPage="pagination"
        @getItems="getModifierGroups"
        @addItem="addModifierGroup"
        @editItem="editModifierGroup"
        @removeItem="removeModifierGroup"
        :items="modifierGroups"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        @currentIndexChanged="currentIndexChanged"
        :getTrigger="getTrigger"
        :changeIndex="currentIndex"
        :hasAdd="authority.IsAdd"
        :hasEdit="authority.IsUpdate"
        :hasRemove="authority.IsRemove"
        :tableName="componentId"
      >
        <template slot="add">
          <button
            id="addNew"
            type="button"
            class="btn btn-outline-primary btn-icon dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="pr-2">
              <i class="fa fa-plus"></i>
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="addNew">
            <a class="dropdown-item" @click="addModifierGroup">新建</a>
            <a
              class="dropdown-item"
              @click="tasteKindSelectTrigger = !tasteKindSelectTrigger"
              >選擇</a
            >
          </div>
        </template>
        <template slot="button">
          <div class="dropdown">
            <button
              id="tasteSequence"
              type="button"
              class="btn btn-outline-info btn-icon"
              @click="sortTasteKinds"
            >
              <i class="fas fa-sort"></i>
            </button>
          </div>
        </template>
      </pageTable>
    </div>
    <div class="col-6">
      <modifier-items :id="onlineOrderModifierGroupId"></modifier-items>
    </div>

    <modifier-group-data
      :isShow="isShowModal"
      :entity="modifierGroup"
      :onlineOrderType="onlineOrderType"
      @save="saveModifierGroup"
      @close="isShowModal = !isShowModal"
    ></modifier-group-data>

    <taste-kind-select
      :trigger="tasteKindSelectTrigger"
      :isMultiple="true"
      @onSaved="addMultipleTasteKind"
    >
    </taste-kind-select>

    <online-order-item-select
      :trigger="itemTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderItems"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveItems"
    ></online-order-item-select>

    <taste-kinds-sort-modal
      :trigger="tasteKindsSortModalTrigger"
      :items="tasteKinds"
      :title="sortModalTitle"
      viewName="TasteKindsSort"
      @saved="saveSortTasteKinds"
    ></taste-kinds-sort-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import modifierGroupData from "@/components/onlineOrders/OnlineOrderModifierGroupData";
import tasteKindSelect from "@/components/selects/TasteKindSelects";
import onlineOrderItemSelect from "@/components/selects/OnlineOrderItemSelects";
import tasteKindsSortModal from "@/components/parts/sortModalPart";
import modifierItems from "@/components/datas/ModifierItems";

export default {
  data() {
    return {
      modifierGroups: [],
      modifierGroup: {},
      pagination: {},
      isShowModal: false,
      columns: [
        {
          name: "OnlineOrderModifierGroupName",
        },
        {
          name: "MinQty",
        },
        {
          name: "MaxQty",
        },
      ],
      idProperty: "OnlineOrderModifierGroupId",
      nameProperty: "OnlineOrderModifierGroupName",
      getTrigger: false,
      authority: {},
      tasteKindSelectTrigger: false,
      itemTrigger: false,
      onlineOrderItems: undefined,
      currentIndex: -1,
      tasteKindsSortModalTrigger: false,
      tasteKinds: [],
      sortModalTitle: null,
      onlineOrderModifierGroupId: null,
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    modifierGroupData,
    tasteKindSelect,
    onlineOrderItemSelect,
    tasteKindsSortModal,
    modifierItems,
  },
  methods: {
    addItems() {
      this.uberEatsItemTrigger = true;
    },
    saveSortTasteKinds(newItems) {
      let items = [];
      newItems.forEach((item) => {
        items.push(item.OnlineOrderModifierGroupId);
      });
      this.$api.onlineOrderModifierGroups
        .postTasteKindsSequence(items)
        .then(() => {
          this.tasteKindsSortModalTrigger = !this.tasteKindsSortModalTrigger;
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    sortTasteKinds() {
      this.sortModalTitle = this.$t("title.tastekindsequence");
      this.tasteKindsSortModalTrigger = !this.tasteKindsSortModalTrigger;
      this.$api.onlineOrderModifierGroups
        .getTasteKindsSequence()
        .then((data) => {
          this.tasteKinds = data;
          this.tasteKinds.forEach((tasteKind) => {
            tasteKind.ColumnName = tasteKind.OnlineOrderModifierGroupName;
          });
        });
    },

    currentIndexChanged(index) {
      this.currentIndex = index;
      if (this.currentIndex < 0) return;
      this.onlineOrderModifierGroupId =
        this.modifierGroups[index].OnlineOrderModifierGroupId;
    },
    getModifierGroups(dTO) {
      dTO.type = this.onlineOrderType;
      const vm = this;
      this.$api.onlineOrderModifierGroups.getPages(dTO).then((data) => {
        vm.modifierGroups = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addModifierGroup() {
      this.modifierGroup = this.$func.defaultModel.getOnlineOrderModifierGroup(
        this.onlineOrderType
      );
      this.isShowModal = !this.isShowModal;
    },
    addMultipleTasteKind(items) {
      this.$func.message.confirm("是否一併加入口味細項?").then((result) => {
        const vm = this;
        this.$api.onlineOrderModifierGroups
          .postBatch({
            OnlineOrderType: this.onlineOrderType,
            TasteKindIds: items.map((x) => x.TasteKindId),
            IsAddDetail: result.isConfirmed,
          })
          .then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
      });
    },
    editModifierGroup(modifierGroup) {
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .get(modifierGroup.OnlineOrderModifierGroupId)
        .then((data) => {
          vm.modifierGroup = data;
          vm.isShowModal = !vm.isShowModal;
        });
    },
    removeModifierGroup(modifierGroup) {
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .remove(modifierGroup.OnlineOrderModifierGroupId)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    saveModifierGroup() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
    showItems() {
      const modifierGroup = this.modifierGroups[this.currentIndex];
      if (!modifierGroup) {
        this.$func.message.alert("請選擇口味 !");
        return;
      }
      this.onlineOrderItems = undefined;
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .getItems(modifierGroup.OnlineOrderModifierGroupId)
        .then((data) => {
          vm.onlineOrderItems = data;
          vm.itemTrigger = !vm.itemTrigger;
        });
    },
    saveItems(items) {
      const modifierGroup = this.modifierGroups[this.currentIndex];
      if (!modifierGroup) {
        this.$func.message.alert("請選擇口味 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .postItems(
          modifierGroup.OnlineOrderModifierGroupId,
          items.map((item) => item.OnlineOrderItemId)
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
