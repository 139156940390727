<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getLicenses"
      @editItem="editLicense"
      @removeItem="removeLicense"
      @trdbClicked="editLicense"
      :items="licenses"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="false"
      :hasEdit="true"
      :hasRemove="true"
      :isSortable="true"
      ><template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBusinessId"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >營業人ID:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessId"
                  v-model="searchDTO.BusinessId"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBusinessName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >營業人名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBusinessName"
                  v-model="searchDTO.BusinessName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="generate(props.index)"
        >
          <i class="fas fa-id-badge"></i>
        </button>
      </template>
      <template slot="th-before">
        <th class="text-center" style="width: 80px">
          {{ $t("columnName.iserp") }}
        </th>
        <th class="text-center" style="width: 80px">
          {{ $t("columnName.isdining") }}
        </th></template
      >
      <template slot="td-before" slot-scope="props">
        <td class="text-center">
          <span v-if="props.item.IsERP">V</span>
        </td>
        <td class="text-center">
          <span v-if="props.item.IsDining">V</span>
        </td>
      </template>
    </search-and-table-part>

    <license-data
      :id="licenseId"
      :modalTitle="modalTitle"
      :trigger="modalTrigger"
      @onSaved="saveLicense"
      @onClosed="onClosed"
    ></license-data>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import licenseData from "@/components/datas/LicenseData";

export default {
  data() {
    return {
      searchDTO: {},
      licenses: [],
      pagination: {},
      columns: [
        { name: "IndustryTypeText" },
        { name: "ExpirationDate" },
        { name: "BusinessId" },
        { name: "BusinessName" },
        { name: "CheckoutInterfaceTypeText" },
        { name: "BuilderName" },
        { name: "CreateTime" },
      ],
      idProperty: "BusinessId",
      nameProperty: "BusinessName",
      getTrigger: false,
      modalTrigger: false,
      licenseId: "",
      modalTitle: "",
    };
  },
  components: {
    searchAndTablePart,
    licenseData,
  },
  methods: {
    generate(index) {
      let message = "";
      if (this.licenses[index].IsLicenseGenerated) {
        message = "確定要重新產生License嗎 ?";
      } else {
        message = "確定要產生License嗎 ?";
      }
      this.$func.message.confirm(message).then((result) => {
        if (result.isConfirmed) {
          this.$api.businesses
            .generateLicense({ LicenseId: this.licenses[index].LicenseId })
            .then(() => {
              this.$func.message.success("產生成功 !");
              this.getTrigger = !this.getTrigger;
            })
            .catch((error) => this.$func.message.error(error.data.Message));
        }
      });
    },
    getLicenses(dTO) {
      const vm = this;
      this.$api.businesses
        .getLicenses({ ...dTO, ...this.searchDTO })
        .then((data) => {
          data.Datas.forEach((item) => {
            item.IndustryTypeText = vm.$enum.industryType.getByValue(
              item.IndustryType
            ).text;
            item.CheckoutInterfaceTypeText =
              vm.$enum.checkoutInterfaceType.getByValue(
                item.CheckoutInterfaceType
              ).text;
          });
          vm.licenses = data.Datas;
          vm.pagination = data.Pagination;
        });
    },
    editLicense(business) {
      this.licenseId = business.LicenseId;
      this.modalTrigger = true;
      this.modalTitle = this.$t("modal.editlicense");
    },
    removeLicense(business) {
      const vm = this;
      this.$api.businesses.remove(business.BusinessId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveLicense() {
      this.onClosed();
      this.getTrigger = !this.getTrigger;
    },
    onClosed() {
      this.modalTrigger = false;
    },
  },
};
</script>
