<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasSupplier="true"
      :hasWarehouse="true"
      :isPurchaseOrder="true"
      :items="purchases"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
    >
      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="print(props.index)"
        >
          <i class="fas fa-print"></i>
        </button>
        <button
          class="btn btn-outline-primary"
          v-if="authority.IsUpdate"
          @click="onPurchaseClicked(props.index)"
        >
          {{ $t("button.topurchase") }}
        </button>
      </template>
    </condition-part>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      purchases: [],
      pagination: {},
      columns: [
        {
          name: "PurchaseDate",
        },
        {
          name: "PurchaseOrderNumber",
          defaultVisible: true,
        },
        {
          name: "SupplierName",
        },
        {
          name: "BuyerName",
        },
        {
          name: "Total",
        },
        {
          name: "Memo",
        },
        {
          name: "CreateTime",
        },
        {
          name: "UpdateTime",
        },
        {
          name: "PurchaseNumber",
          headerText: "進貨單號",
        },
      ],
      nameProperty: "PurchaseNumber",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    getItems(condition) {
      condition.TradeType = 7;
      const vm = this;
      this.$api.purchaseOrders.getPages(condition).then((data) => {
        vm.purchases = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    add() {
      this.$router.push({ name: `PurchaseOrderAdd` });
    },
    edit(item) {
      if (item.IsTurn) {
        this.$func.message.alert("此單據已轉進貨單，無法編輯 !");
        return;
      }
      this.$router.push({
        name: `PurchaseOrderEdit`,
        params: { id: item.PurchaseId },
      });
    },
    remove(item) {
      if (item.IsTurn) {
        this.$func.message.alert("此單據已轉進貨單，無法刪除 !");
        return;
      }
      const vm = this;
      this.$api.purchaseOrders.delete(item.PurchaseId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    print(index) {
      let purchase = this.purchases[index];
      if (!purchase) {
        this.$func.message.alert("請選擇一筆單據 !");
      } else {
        this.$api.purchaseOrders.print(purchase.PurchaseId);
      }
    },
    onPurchaseClicked(index) {
      const purchase = this.purchases[index];
      if (purchase) {
        if (purchase.IsTurn) {
          this.$func.message.alert("此單據已轉進貨單 !");
          return;
        }
        const vm = this;
        this.$func.message.confirm("確定要轉進貨單嗎 ?").then((result) => {
          if (result.isConfirmed) {
            vm.$router.push({
              name: "PurchaseOrderTurn",
              params: { orderid: purchase.PurchaseId },
            });
          }
        });
      } else this.$func.message.alert("請選擇一筆資料 !");
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
