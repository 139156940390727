<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasWarehouse="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasFoodSupplier="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "WarehouseNumber" },
        { name: "WarehouseName" },
        { name: "FoodNumber" },
        { name: "FoodName" },
        { name: "SupplierNumber" },
        { name: "SupplierName" },
        { name: "InitialQty" },
        { name: "LastQty" },
        { name: "SalQty" },
        { name: "SalPrice" },
        { name: "SalTotal" },
        { name: "PurchaseQty" },
        { name: "PurchasePrice" },
        { name: "PurchaseTotal" },
        { name: "AdjustQty" },
      ],
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getERPSummary(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getERPSummary(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printERPSummary(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getERPSummary(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
