import { httpPost } from "@/request/http";
import func from "@/funcs";

export default {
  login(account, password, businessId) {
    return new Promise((resolve, reject) => {
      httpPost("/token", { account, password, businessId })
        .then((data) => {
          func.localStorage.set("token", data.Token);
          func.localStorage.set("isERP", data.IsERP);
          func.localStorage.set("isCustomer", data.IsCustomer);
          func.localStorage.set("hasOnlineOrder", data.HasOnlineOrder);
          func.localStorage.set("isFoodBySelect", data.IsFoodBySelect);
          func.localStorage.set("userId", data.UserId);
          func.localStorage.set("userName", data.UserName);
          func.localStorage.set("erpType", data.ERPType);
          func.localStorage.set("roleType", data.RoleType);
          func.localStorage.set("productType", data.ProductType);
          func.localStorage.set("industryType", data.IndustryType);
          func.localStorage.set("hasOcard", data.HasOcard);
          func.localStorage.set("hasUberEats", data.HasUberEats);
          func.localStorage.set(
            "hasCloudElecInvoice",
            data.HasCloudElecInvoice
          );
          func.localStorage.set("hasLineOrder", data.HasLineOrder);
          func.localStorage.set("hasPGTalk", data.HasPGTalk);
          func.localStorage.set("hasMyCard", data.HasMyCard);
          func.localStorage.set("hasPXPay", data.HasPXPay);
          func.localStorage.set("hasSurveyCake", data.HasSurveyCake);
          func.localStorage.set("hasMemberWeb", data.HasMemberWeb);
          func.localStorage.set("hasVision", data.HasVision);
          func.localStorage.set("hasBatchNumber", data.HasBatchNumber);
          func.localStorage.set("hasShowmore", data.HasShowmore);
          func.localStorage.set("hasSpecification", data.HasSpecification);
          func.localStorage.set(
            "isElecInvoiceCanPreviou",
            data.IsElecInvoiceCanPrevious
          );
          func.localStorage.set("hasEnglish", data.HasEnglish);
          func.localStorage.set("hasJapanese", data.HasJapanese);
          func.localStorage.set("hasKorean", data.HasKorean);
          func.localStorage.set("hasFrench", data.HasFrench);
          func.localStorage.set("payType1", JSON.stringify(data.PayType1));
          func.localStorage.set("payType2", JSON.stringify(data.PayType2));
          func.localStorage.set("isDining", data.IsDining);
          resolve(data);
        })
        .catch((error) => {
          if (error.data.Message) {
            func.message.error(error.data.Message);
          } else {
            func.message.error("登入失敗 !");
          }
          reject(error);
        });
    });
  },
  logout() {
    func.localStorage.removeAll();
  },
};
