export function getAll() {
  var items = [
    {
      value: 0,
      name: "Default",
      text: "一般",
    },
    {
      value: 1,
      name: "Retail",
      text: "零售",
    },
  ];
  return items;
}
export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
