<template>
  <basic-selects
    :trigger="trigger"
    :isMultiple="isMultiple"
    :columns="columns"
    :items="selectableItems"
    title="選擇商品"
    idProperty="OnlineOrderItemId"
    nameProperty="OnlineOrderItemName"
    allSelectId="modifierItemAllSelectBox"
    @onSaved="onSaved"
    :id="id"
    :pagination="pagination"
    @getItems="getItems"
    sortBy="OnlineOrderItemName"
    :defaultItems="defaultItems"
    viewName="ModifierItemSelects"
  ></basic-selects>
</template>

<script>
import basicSelects from "./BasicSelects";

export default {
  data() {
    return {
      searchTypes: null,
      selectableItems: [],
      columns: [
        {
          name: "OnlineOrderItemName",
        },
      ],
      id: "itemSelectModal",
      pagination: {},
    };
  },
  props: {
    trigger: false,
    isMultiple: false,
    currentId: undefined,
    modalId: undefined,
    defaultItems: undefined,
  },
  watch: {
    modalId: {
      handler(value) {
        if (value) this.id = value;
      },
      immediate: true,
    },
    trigger() {
      this.getItems();
    },
  },
  components: {
    basicSelects,
  },
  methods: {
    onSaved(items) {
      this.$emit("onSaved", items);
    },
    getItems() {
      if (!this.id) return;
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .getSelectableItems(vm.currentId)
        .then((data) => {
          vm.selectableItems = data;
        });
    },
  },
};
</script>
