<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasCustomer="hasCustomer"
      :items="sals"
      :pagination="pagination"
      :columns="columns"
      @getItems="getItems"
      @addItem="add"
      @editItem="edit"
      @removeItem="remove"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isReturn="isReturn"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      :isSortable="true"
      :reportTypes="reportTypes"
    >
      <template slot="searchButtons">
        <import-part
          @onImport="onImport"
          :title="`${$t('modal.importsale')}`"
        />
        <export-part @onExport="onExport" />
      </template>

      <template slot="dataButtons" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon"
          @click="print(props.index)"
        >
          <i class="fas fa-print"></i>
        </button>
        <button
          type="button"
          v-if="hasCloudElecInvoice"
          class="btn btn-outline-info btn-icon"
          @click="invoice(props.index)"
        >
          <i class="fas fa-file-invoice-dollar"></i>
        </button>
      </template>
      <th
        class="text-center"
        v-if="hasCloudElecInvoice"
        slot="th-before"
        style="width: 80px"
      >
        {{ $t("columnName.isvoid") }}
      </th>
      <td
        class="text-center"
        v-if="hasCloudElecInvoice"
        slot="td-before"
        slot-scope="props"
      >
        <span v-if="props.item.IsVoid">V</span>
      </td>

      <div
        v-if="hasCloudElecInvoice && reportType == 'import'"
        slot="middleArea"
      >
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-info btn-icon"
              @click="batchInvoice"
            >
              <i class="fas fa-tasks"></i>
            </button>
          </div>
          <data-body-part
            :parentPage="middleParentPage"
            :columns="middleColumns"
            :items="middleItems"
            :idProperty="middleIdProperty"
            :nameProperty="middleNameProperty"
            :getTrigger="middleGetTrigger"
            @getItems="middleGetItems"
            @currentIndexChanged="middleCurrentIndexChanged"
            :changeIndex="middleCurrentIndex"
            :viewName="middleViewName"
            :tableName="middleTableName"
          >
          </data-body-part>
        </div>
      </div>
    </condition-part>

    <sal-invoice
      v-if="hasCloudElecInvoice"
      :invoiceTrigger="invoiceTrigger"
      :sal="currentSal"
      @invoiceSaved="invoiceSaved"
    ></sal-invoice>
    <sal-invoice-function
      v-if="hasCloudElecInvoice"
      :invoiceTrigger="invoiceFunctionTrigger"
      :sal="currentSal"
      @invoiceSaved="invoiceSaved"
    ></sal-invoice-function>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import salInvoice from "@/components/erps/SalInvoice";
import salInvoiceFunction from "@/components/erps/SalInvoiceFunction";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";
import dataBodyPart from "@/components/parts/dataBodyPart";

export default {
  data() {
    return {
      sals: [],
      isReturn: false,
      pagination: {},
      nameProperty: "SalNumber",
      getTrigger: false,
      invoiceTrigger: false,
      invoiceFunctionTrigger: false,
      currentSal: undefined,
      authority: {},
      reportTypes: ["standard", "import"],
      reportType: "standard",
      middleParentPage: {},
      middleColumns: [
        {
          name: "CreateTime",
          headerText: "時間",
        },
        {
          name: "EmployeeName",
        },
        {
          name: "Counts",
          headerText: "筆數",
        },
      ],
      middleItems: [],
      middleIdProperty: "SalId",
      middleNameProperty: "EmployeeName",
      middleGetTrigger: false,
      middleCurrentIndex: -1,
      middleViewName: "ImportData",
      middleTableName: "importData",
      middleCondition: {},
      currentCondition: {},
    };
  },
  computed: {
    productType() {
      return window.localStorage["productType"];
    },
    hasCustomer() {
      return this.reportType == "standard";
    },
    routerName() {
      return this.isReturn ? "SalReturn" : "Sal";
    },
    hasCloudElecInvoice() {
      return (
        window.localStorage["productType"] == 3 ||
        window.localStorage["hasCloudElecInvoice"] == "true"
      );
    },
    columns() {
      var cols = [];
      if (this.hasCloudElecInvoice) {
        cols = [
          {
            name: "IsInvoiceHasSend",
            headerText: "發票寄送Mail",
            width: 120,
            class: "text-center",
          },
          {
            name: "IsInvoiceHasPrint",
            headerText: "發票列印",
            width: 90,
            class: "text-center",
          },
          { name: "BusinessDay" },
          { name: "SalNumber" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "CreateTime" },
          { name: "UpdateTime" },
          { name: "InvoiceNumber" },
          { name: "UniformNumber" },
          { name: "VehicleNumber" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "Memo" },
        ];
      } else {
        cols = [
          { name: "BusinessDay" },
          { name: "SalNumber" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "CreateTime" },
          { name: "UpdateTime" },
          { name: "InvoiceNumber" },
          { name: "UniformNumber" },
          { name: "VehicleNumber" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "Memo" },
        ];
      }
      if (this.productType == 2 || this.hasCloudElecInvoice) {
        cols.splice(10, 0, { name: "SalTime" });
      }
      return cols;
    },
  },
  components: {
    conditionPart,
    salInvoice,
    salInvoiceFunction,
    importPart,
    exportPart,
    dataBodyPart,
  },
  methods: {
    batchInvoice() {
      this.$api.sals
        .getImportDetail(
          this.middleItems[this.middleCurrentIndex].SaleImportLogId
        )
        .then((data) => {
          if (data.length > 0) {
            history.pushState(null, null, document.URL);
            window.addEventListener("popstate", this.banBackPage, false);
            window.onbeforeunload = function () {
              return "";
            };
            this.$func.message
              .confirm("確定開立發票及寄送Email嗎 ?")
              .then(async (result) => {
                if (result.isConfirmed) {
                  for (let i = 0; i < data.length; i++) {
                    let status = true;
                    await this.$api.sals
                      .elecInvoice({ SalId: data[i], InvoiceTargetType: 2 })
                      .catch((error) => {
                        this.$func.message
                          .confirm(
                            error.data.Message,
                            "要繼續開立下一張發票嗎 ?"
                          )
                          .then((ans) => {
                            status = ans.isConfirmed;
                          });
                      });
                    if (!status) {
                      break;
                    }
                  }
                }
                this.getImportDetail(this.middleCurrentIndex);
                window.removeEventListener("popstate", this.banBackPage);
                window.onbeforeunload = function () {
                  return null;
                };
              });
          } else {
            this.$func.message.alert(
              "此匯入單據已全部完成<br>開立發票及寄送Email"
            );
          }
        });
    },
    middleGetItems(condition) {
      if (condition) {
        this.middleCondition.Page = condition.page;
        this.middleCondition.PageSize = condition.pagesize;
        this.middleCondition.SortName = condition.sortName;
        this.middleCondition.IsDesc = condition.isDesc;
      }
      this.$api.sals.getImportData(this.middleCondition).then((data) => {
        this.middleItems = data.Datas;
        this.middleParentPage = data.Pagination;
      });
    },
    middleCurrentIndexChanged(index) {
      this.middleCurrentIndex = index;
      if (index > -1) {
        this.getImportDetail(this.middleCurrentIndex);
      } else {
        this.sals = [];
      }
    },
    getImportDetail(index) {
      this.currentCondition.ImportLogId =
        this.middleItems[index].SaleImportLogId;
      this.currentCondition.TradeType = 5;
      this.$api.sals.getPages(this.currentCondition).then((data) => {
        this.sals = data.Datas;
        this.sals.forEach((item) => {
          if (item.IsElecInvoicePrint) {
            item.IsInvoiceHasPrint = "V";
          } else {
            item.IsInvoiceHasPrint = "";
          }
          if (item.IsElecInvoiceSendEmail) {
            item.IsInvoiceHasSend = "V";
          } else {
            item.IsInvoiceHasSend = "";
          }
        });
        this.pagination = data.Pagination;
      });
    },
    getItems(condition) {
      if (condition.ReportType == "import") {
        this.currentCondition = {
          Page: condition.Page,
          PageSize: condition.PageSize,
          SortName: condition.sortName,
          IsDesc: condition.isDesc,
        };
        if (condition.ReportType !== this.reportType) {
          this.sals = [];
          this.middleCondition.DayBegin = condition.DayBegin;
          this.middleCondition.DayEnd = condition.DayEnd;
          this.middleGetItems({
            Page: 1,
            PageSize: 25,
            SortName: "",
            IsDesc: 0,
          });
        } else {
          if (
            this.middleCondition.DayBegin !== condition.DayBegin ||
            this.middleCondition.DayEnd !== condition.DayEnd
          ) {
            this.sals = [];
            this.middleCondition.DayBegin = condition.DayBegin;
            this.middleCondition.DayEnd = condition.DayEnd;
            this.middleGetItems({
              Page: 1,
              PageSize: 25,
              SortName: "",
              IsDesc: 0,
            });
          } else {
            this.currentCondition = condition;
            this.getImportDetail(this.middleCurrentIndex);
          }
        }
      } else {
        condition.SourceType = 4;
        condition.TradeType = this.isReturn ? 6 : 5;
        this.currentCondition = condition;
        delete this.currentCondition.ImportLogId;

        const vm = this;
        this.$api.sals.getPages(this.currentCondition).then((data) => {
          vm.sals = data.Datas;
          vm.sals.forEach((item) => {
            if (item.IsElecInvoicePrint) {
              item.IsInvoiceHasPrint = "V";
            } else {
              item.IsInvoiceHasPrint = "";
            }
            if (item.IsElecInvoiceSendEmail) {
              item.IsInvoiceHasSend = "V";
            } else {
              item.IsInvoiceHasSend = "";
            }
          });
          vm.pagination = data.Pagination;
        });
      }
      if (this.hasCloudElecInvoice || this.$route.name == "Sals") {
        this.reportType = condition.ReportType;
      }
    },
    add() {
      this.$router.push({ name: `${this.routerName}Add` });
    },
    edit(item) {
      if (item.InvoiceNumber) {
        this.$func.message.alert("此單據已開立發票無法編輯 !");
        return;
      }
      if (!this.hasCloudElecInvoice && item.Total != item.Receivable) {
        this.$func.message.alert("此單據已收款無法編輯 !");
        return;
      }
      this.$router.push({
        name: `${this.routerName}Edit`,
        params: { id: item.SalId },
      });
    },
    remove(item) {
      if (item.InvoiceNumber) {
        this.$func.message.alert("此單據已開立發票無法刪除 !");
        return;
      }
      if (!this.hasCloudElecInvoice && item.Total != item.Receivable) {
        this.$func.message.alert("此單據已收款無法刪除 !");
        return;
      }
      const vm = this;
      this.$api.sals.delete(item.SalId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    print(index) {
      let sal = this.sals[index];
      if (!sal) {
        this.$func.message.alert("請選擇一筆銷貨單 !");
      } else {
        this.$api.sals.print(sal.SalId);
      }
    },
    invoice(index) {
      let sal = this.sals[index];
      if (!sal) {
        this.$func.message.alert("請選擇一筆銷貨單 !");
        return;
      }
      if (sal.IsVoid) {
        this.$func.message.alert("此銷貨單已作廢 !");
        return;
      }
      this.currentSal = sal;
      if (sal.InvoiceNumber) {
        this.invoiceFunctionTrigger = !this.invoiceFunctionTrigger;
      } else {
        this.invoiceTrigger = !this.invoiceTrigger;
      }
    },
    invoiceSaved() {
      this.getTrigger = !this.getTrigger;
    },
    banBackPage() {
      alert("匯入尚未完成，請勿離開此頁 !");
      history.pushState(null, null, document.URL);
    },
    onImport(file) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.banBackPage, false);
      window.onbeforeunload = function () {
        return "";
      };
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.sals
        .postSalExcel(formData)
        .then((res) => {
          vm.getTrigger = !vm.getTrigger;
          if (vm.hasCloudElecInvoice) {
            vm.$func.message
              .confirm("要同時開立發票及寄送Email嗎 ?")
              .then(async (result) => {
                if (result.isConfirmed) {
                  for (let i = 0; i < res.length; i++) {
                    let status = true;
                    await vm.$api.sals
                      .elecInvoice({ SalId: res[i], InvoiceTargetType: 2 })
                      .catch((error) => {
                        vm.$func.message
                          .confirm(
                            error.data.Message,
                            "要繼續開立下一張發票嗎 ?"
                          )
                          .then((ans) => {
                            status = ans.isConfirmed;
                          });
                      });
                    if (!status) {
                      break;
                    }
                  }
                }
                vm.getTrigger = !vm.getTrigger;
                window.removeEventListener("popstate", this.banBackPage);
                window.onbeforeunload = function () {
                  return null;
                };
              });
          }
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
    onExport() {
      this.$api.sals.getSalExcel();
    },
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.banBackPage);
    window.onbeforeunload = function () {
      return null;
    };
  },
  created() {
    const name = this.$route.name;
    if (name.toLowerCase() == "salreturns") {
      this.isReturn = true;
      this.reportTypes = null;
    }
    this.reportType = "standard";
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
