<template>
  <item-modal
    @onClosed="onClosed"
    @onSaved="onSaved"
    :title="modalTitle"
    :trigger="modalTrigger"
    modalId="licenseData"
  >
    <div slot="item">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="expirationDate">到期時間</label>
            <input
              type="date"
              class="form-control"
              id="expirationDate"
              v-model="license.ExpirationDate"
              required
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="industryType">行業別</label>
            <select
              class="form-control"
              id="industryType"
              v-model="license.IndustryType"
              required
            >
              <option
                v-for="industryType in industryTypes"
                :key="industryType.value"
                :value="industryType.value"
              >
                {{ industryType.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="businessId">營業人ID</label>
            <input
              type="text"
              class="form-control"
              id="businessId"
              v-model="license.BusinessId"
              required
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="businessName">營業人名稱</label>
            <input
              type="text"
              class="form-control"
              id="businessName"
              v-model="license.BusinessName"
              required
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="checkoutInterfaceType">收銀畫面類型</label>
            <select
              class="form-control"
              id="checkoutInterfaceType"
              v-model="license.CheckoutInterfaceType"
              required
            >
              <option
                v-for="checkoutInterfaceType in checkoutInterfaceTypes"
                :key="checkoutInterfaceType.value"
                :value="checkoutInterfaceType.value"
              >
                {{ checkoutInterfaceType.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-6">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isERP"
                v-model="license.IsERP"
                required
              />
              <label class="custom-control-label" for="isERP">進銷存</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isDining"
                v-model="license.IsDining"
                required
              />
              <label class="custom-control-label" for="isDining">後結</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </item-modal>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      license: {},
      industryTypes: this.$enum.industryType.getAll(),
      checkoutInterfaceTypes: this.$enum.checkoutInterfaceType.getAll(),
    };
  },
  props: ["trigger", "id", "number", "modalTitle"],
  watch: {
    trigger(value) {
      if (value) {
        this.$api.businesses.getLicense(this.id).then((data) => {
          this.license = data;
          this.modalTrigger = !this.modalTrigger;
        });
      }
    },
  },
  components: {
    itemModal,
  },
  methods: {
    onSaved() {
      const vm = this;
      this.$api.businesses
        .saveLicense(vm.id, vm.license)
        .then(() => {
          vm.$emit("onSaved");
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
