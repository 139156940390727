<template>
  <div class="card-body card-table-body">
    <div class="row mb-2 px-3">
      <div class="form-inline" v-if="showPage">
        <label for="pageSize" class="mx-2">Show</label>
        <select
          class="form-control-sm"
          v-model="pagination.PageSize"
          @change="sizeChanged"
        >
          <option v-for="size in sizes" :key="size" :value="size">
            {{ size }}
          </option>
        </select>
        <label for="pageSize" class="mx-2"></label>
      </div>
      <div class="form-inline d-flex align-items-center" v-if="searchTypes">
        <label class="mx-2">{{ $t("dataBodyPart.search") }}:</label>
        <select
          class="form-control-sm"
          style="width: 120px"
          v-model="searchType"
        >
          <option
            v-for="searchType in searchTypes"
            :key="searchType.value"
            :value="searchType.value"
          >
            {{ searchType.text }}
          </option>
        </select>
        <input
          class="form-control-sm mx-2"
          style="width: 120px"
          type="text"
          v-model="searchText"
        />
        <button
          type="button"
          class="btn btn-outline-primary btn-icon px-3"
          @click="getItems"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="form-inline d-flex align-items-center" v-if="qtyTypes">
        <label class="mx-2">{{ $t("dataBodyPart.qty") }}</label>
        <select class="form-control-sm" style="width: 120px" v-model="qtyType">
          <option
            v-for="qtyType in qtyTypes"
            :key="qtyType.value"
            :value="qtyType.value"
          >
            {{ qtyType.text }}
          </option>
        </select>
      </div>
      <div class="form-inline d-flex align-items-center" v-if="directoryKinds">
        <label class="mx-2">{{ $t("dataBodyPart.directorykind") }}:</label>
        <select
          class="form-control-sm"
          style="width: 120px"
          v-model="directoryKindId"
          @change="getItems"
        >
          <option
            v-for="directoryKind in directoryKinds"
            :key="directoryKind.DirectoryKindId"
            :value="directoryKind.DirectoryKindId"
          >
            {{ directoryKind.DirectoryKindName }}
          </option>
        </select>
      </div>
    </div>

    <table-part
      :columns="columns"
      :items="items"
      :idProperty="idProperty"
      :modalId="modalId"
      @currentIndexChanged="currentIndexChanged"
      @click="click"
      @dbClicked="trdbClicked"
      :changeIndex="currentIndex"
      :viewName="viewName"
      :tableName="tableName"
      :noInitialTableName="noInitialTableName"
      :initialWidth="initialWidth"
      :isSortable="isSortable"
      :isMultiple="isMultiple"
      @getSort="getSort"
      :hasTotal="hasTotal"
    >
      <template slot="th-before">
        <slot name="th-before" />
      </template>
      <template slot="th-after">
        <slot name="th-after" />
      </template>
      <template slot="td-before" slot-scope="props">
        <slot name="td-before" :item="props.item" />
      </template>
      <template slot="td-after" slot-scope="props">
        <slot name="td-after" :item="props.item" />
      </template>
    </table-part>

    <pagination-part
      v-if="showPage"
      :pagination="pagination"
      @pageChanged="pageChanged"
    ></pagination-part>
  </div>
</template>

<script>
import tablePart from "@/components/parts/tablePart";
import paginationPart from "@/components/parts/paginationPart";

export default {
  data() {
    return {
      sizes: [10, 25, 50, 100, 250, 500],
      pagination: this.$func.defaultModel.getPage(),
      currentIndex: -1,
      searchType: 0,
      searchText: "",
      sortName: "",
      isDesc: 0,
      directoryKindId: "",
      qtyType: 0,
    };
  },
  components: {
    tablePart,
    paginationPart,
  },
  props: {
    parentPage: Object,
    hasPage: {
      type: Boolean,
      default: true,
    },
    items: Array,
    columns: Array,
    idProperty: String,
    nameProperty: String,
    getTrigger: false,
    searchTypes: Array,
    directoryKinds: Array,
    currentDirectoryKind: String,
    changeIndex: {
      type: Number,
      default: -1,
    },
    noInitialLoading: false,
    viewName: String,
    tableName: String,
    noInitialTableName: false,
    initialWidth: String,
    isSortable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    modalId: String,
    qtyTypes: Array,
    hasTotal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    qtyType() {
      this.getItems();
    },
    parentPage(value) {
      if (value) this.pagination = value;
    },
    getTrigger() {
      this.getItems();
    },
    changeIndex: function (value) {
      this.currentIndex = value;
    },
    currentDirectoryKind(value) {
      this.directoryKindId = value;
    },
    directoryKinds() {
      this.directoryKindId = this.directoryKinds[0].DirectoryKindId;
      this.getItems();
    },
  },
  computed: {
    showPage() {
      return this.pagination && this.hasPage;
    },
  },
  methods: {
    getSort(status) {
      this.sortName = status.sortName;
      this.isDesc = status.isDesc;
      this.getItems();
    },
    sizeChanged() {
      this.pagination.CurrentPage = 1;
      this.getItems();
    },
    pageChanged(page) {
      this.pagination.CurrentPage = page;
      this.getItems();
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
      this.$emit("currentIndexChanged", this.currentIndex);
    },
    getItems() {
      this.currentIndex = -1;
      const vm = this;
      if (this.directoryKinds) {
        this.$emit("getItems", {
          page: vm.pagination.CurrentPage,
          pagesize: vm.pagination.PageSize,
          sortName: vm.sortName,
          isDesc: vm.isDesc,
          directoryKindId: vm.directoryKindId,
        });
        return;
      }
      if (this.qtyTypes) {
        this.$emit("getItems", {
          page: vm.pagination.CurrentPage,
          pagesize: vm.pagination.PageSize,
          sortName: vm.sortName,
          isDesc: vm.isDesc,
          qtyType: vm.qtyType,
        });
        return;
      }
      this.$emit("getItems", {
        page: vm.pagination.CurrentPage,
        pagesize: vm.pagination.PageSize,
        searchType: vm.searchType,
        searchText: vm.searchText,
        sortName: vm.sortName,
        isDesc: vm.isDesc,
      });
    },
    click(item) {
      this.$emit("click", item);
    },
    trdbClicked(item) {
      this.$emit("trdbClicked", item);
    },
  },
  mounted() {
    if (!this.noInitialLoading) {
      this.getItems();
    }
  },
};
</script>
