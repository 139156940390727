<template>
  <div class="ubereats-page-container row p-0">
    <div class="ubereats-tabs col w-100">
      <div class="ubereats-tabs-container col-12 w-100">
        <div
          class="tab-container"
          :class="{
            tabSelected: this.componentId == 'onlineOrderDevice',
          }"
          @click.prevent="deviceClick"
        >
          <h5>終端</h5>
        </div>
        <div
          class="tab-container"
          :class="{ tabSelected: this.componentId == 'onlineOrderMenu' }"
          @click.prevent="menuClick"
        >
          <h5>菜單</h5>
        </div>
        <div
          class="tab-container"
          :class="{ tabSelected: this.componentId == 'onlineOrderCategory' }"
          @click.prevent="categoryClick"
        >
          <h5>類別</h5>
        </div>
        <div
          class="tab-container"
          :class="{ tabSelected: this.componentId == 'onlineOrderItem' }"
          @click.prevent="itemClick"
        >
          <h5>商品</h5>
        </div>
        <div
          class="tab-container"
          :class="{
            tabSelected: this.componentId == 'onlineOrderModifierGroup',
          }"
          @click.prevent="tasteClick"
        >
          <h5>自訂項目</h5>
        </div>
      </div>
    </div>
    <div class="ubereats-table-container col w-100">
      <div class="ubereats-table row">
        <div class="ubereats-table-title col-12">
          <h4 class="d-inline-block align-middle" v-if="title">
            {{ title }}
          </h4>
          <button
            class="btn btn-primary text-light float-right"
            @click="loginUberEats"
          >
            綁定
          </button>
          <!-- <button
            v-if="componentId == 'onlineOrderDevice'"
            class="btn btn-warning ubereats-sync-color text-light float-right"
            @click="upload"
          >
            菜單更新
          </button> -->
        </div>
        <component
          class="col-12"
          :is="componentId"
          v-if="componentId"
          :componentId="componentId"
          :onlineOrderType="1"
        ></component>
      </div>
    </div>
  </div>
</template>
<script>
import onlineOrderMenu from "@/components/onlineOrders/OnlineOrderMenu";
import onlineOrderCategory from "@/components/onlineOrders/OnlineOrderCategory";
import onlineOrderItem from "@/components/onlineOrders/OnlineOrderItem";
import onlineOrderModifierGroup from "@/components/onlineOrders/OnlineOrderModifierGroup";
import onlineOrderDevice from "@/components/onlineOrders/OnlineOrderDevice";

export default {
  data() {
    return {
      componentId: "",
      title: "",
    };
  },
  components: {
    onlineOrderMenu,
    onlineOrderCategory,
    onlineOrderItem,
    onlineOrderModifierGroup,
    onlineOrderDevice,
  },
  methods: {
    deviceClick() {
      this.componentId = "onlineOrderDevice";
      this.title = "終端";
    },
    menuClick() {
      this.componentId = "onlineOrderMenu";
      this.title = "菜單";
    },
    categoryClick() {
      this.componentId = "onlineOrderCategory";
      this.title = "類別";
    },
    itemClick() {
      this.componentId = "onlineOrderItem";
      this.title = "商品";
    },
    tasteClick() {
      this.componentId = "onlineOrderModifierGroup";
      this.title = "自訂項目";
    },
    loginUberEats() {
      location.href = `${process.env.VUE_APP_UBEREATS_URL}`;
    },
    upload() {
      const vm = this;
      this.$api.uberEats
        .upload()
        .then(() => {
          vm.$func.message.success("同步成功 !");
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  mounted() {
    this.deviceClick();
  },
};
</script>
