<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getItems"
      @addItem="addItem"
      @editItem="editItem"
      @removeItem="removeItem"
      @trdbClicked="editItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      @currentIndexChanged="currentIndexChanged"
      :getTrigger="getTrigger"
      :changeIndex="currentIndex"
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      viewName="modifierItems"
    >
      <template slot="add">
        <button
          id="addNew"
          type="button"
          class="btn btn-outline-primary btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="pr-2">
            <i class="fa fa-plus"></i>
          </span>
        </button>
        <div class="dropdown-menu" aria-labelledby="addNew">
          <a
            class="dropdown-item"
            @click="modifierItemTrigger = !modifierItemTrigger"
            >商品</a
          >
          <a
            class="dropdown-item"
            @click="modifierTasteTrigger = !modifierTasteTrigger"
            >口味</a
          >
        </div>
      </template>
      <template slot="button">
        <div class="dropdown">
          <button
            id="tasteSequence"
            type="button"
            class="btn btn-outline-info btn-icon"
            @click="sortTastes"
          >
            <i class="fas fa-sort"></i>
          </button>
        </div>
      </template>
    </pageTable>
    <itemModal
      modalId="editItemModal"
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveItem"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="form-group col-12">
            <label for="onlineOrderModifierItemName">選項名稱</label>
            <input
              type="text"
              class="form-control"
              id="onlineOrderModifierItemName"
              v-model="item.OnlineOrderModifierName"
              required
            />
          </div>
          <div class="form-group col-12">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customizeOnlineOrderModifierItemPrice"
                v-model="editPrice"
              />
              <label
                class="custom-control-label"
                for="customizeOnlineOrderModifierItemPrice"
                >自訂價格</label
              >
            </div>
          </div>
          <div class="form-group col-12">
            <input
              type="text"
              class="form-control"
              id="onlineOrderModifierItemPrice"
              v-model="newPrice"
              :required="editPrice"
              :readonly="!editPrice"
            />
          </div>
        </div>
      </div>
    </itemModal>
    <modifier-item-select
      :trigger="modifierItemTrigger"
      :isMultiple="true"
      :currentId="id"
      @onSaved="saveModifierItems"
    ></modifier-item-select>
    <modifier-taste-select
      :trigger="modifierTasteTrigger"
      :isMultiple="true"
      :currentId="id"
      @onSaved="saveModifierTastes"
    ></modifier-taste-select>
    <tastes-sort-modal
      :trigger="tastesSortModalTrigger"
      :items="sortItems"
      :title="sortModalTitle"
      viewName="TastesSort"
      @saved="saveSortTastes"
    ></tastes-sort-modal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import modifierItemSelect from "@/components/selects/ModifierItemSelects";
import modifierTasteSelect from "@/components/selects/ModifierTasteSelects";
import tastesSortModal from "@/components/parts/sortModalPart";

export default {
  data() {
    return {
      items: [],
      item: {},
      sortItems: [],
      pagination: {},
      isShowModal: false,
      modalTitle: "",
      columns: [
        { name: "OnlineOrderModifierName", headerText: "選項名稱" },
        { name: "ShowPrice", headerText: "價格" },
      ],
      idProperty: "OnlineOrderModifierId",
      nameProperty: "OnlineOrderModifierName",
      getTrigger: false,
      currentIndex: -1,
      tasteTrigger: false,
      sortModalTitle: null,
      tastesSortModalTrigger: false,
      modifierItemTrigger: false,
      modifierTasteTrigger: false,
      editPrice: false,
      newPrice: 0,
    };
  },
  props: ["id"],
  watch: {
    id() {
      this.getTrigger = !this.getTrigger;
    },
    editPrice(value) {
      if (value) {
        if (this.item.Price) this.newPrice = this.item.Price;
        else this.newPrice = "";
      } else {
        if (this.item.TasteId) this.newPrice = this.item.TastePrice;
        else if (this.item.OnlineOrderItemId)
          this.newPrice = this.item.ItemPrice;
      }
    },
  },
  components: {
    pageTable,
    itemModal,
    modifierItemSelect,
    modifierTasteSelect,
    tastesSortModal,
  },
  methods: {
    sortTastes() {
      if (!this.id) return;
      this.sortModalTitle = this.$t("title.tastesequence");
      this.tastesSortModalTrigger = !this.tastesSortModalTrigger;
      this.$api.onlineOrderModifierGroups
        .getTastesSequence(this.id)
        .then((data) => {
          this.sortItems = data;
        });
    },
    saveSortTastes(newItems) {
      let items = [];
      newItems.forEach((item) => {
        items.push(item.OnlineOrderModifierId);
      });
      this.$api.onlineOrderModifierGroups
        .postTastesSequence(this.id, items)
        .then(() => {
          this.tastesSortModalTrigger = !this.tastesSortModalTrigger;
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getCurrentId() {
      if (this.currentIndex < 0) {
        this.$func.message.alert("請選擇口味 !");
        return undefined;
      }
      const taste = this.items[this.currentIndex];
      if (!taste) {
        return undefined;
      } else {
        return taste.TasteId;
      }
    },
    getItems(dTO) {
      if (!this.id) return;
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .getModifierItems(vm.id, dTO)
        .then((data) => {
          vm.items = data.Datas;
          vm.items.forEach((item) => {
            if (item.Price !== null) {
              item.ShowPrice = "$" + item.Price;
            } else {
              if (item.TasteId) item.ShowPrice = "*$" + item.TastePrice;
              else if (item.OnlineOrderItemId)
                item.ShowPrice = "*$" + item.ItemPrice;
            }
          });
          vm.pagination = data.Pagination;
        });
    },
    editItem(item) {
      this.item = item;
      this.editPrice = item.Price === null ? false : true;
      if (item.OnlineOrderItemId && item.Price === null)
        this.newPrice = item.ItemPrice;
      else if (item.TasteId && item.Price === null)
        this.newPrice = item.TastePrice;
      else if (item.Price >= 0) this.newPrice = item.Price;
      this.modalTitle = "編輯選項";
      this.isShowModal = !this.isShowModal;
    },
    removeItem(item) {
      this.$api.onlineOrderModifierGroups
        .removeModifierItem(item.OnlineOrderModifierId)
        .then(() => {
          this.$func.message.success(
            `已刪除選項「${item.OnlineOrderModifierName}」`
          );
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    saveItem() {
      if (!this.editPrice) this.item.Price = null;
      else this.item.Price = parseInt(this.newPrice);

      this.$api.onlineOrderModifierGroups
        .postModifierItem(this.item)
        .then(() => {
          this.$func.message.success("已修改選項");
          this.getTrigger = !this.getTrigger;
          this.isShowModal = !this.isShowModal;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    saveModifierItems(items) {
      let ids = [];
      items.forEach((item) => {
        ids.push({ OnlineOrderItemId: item.OnlineOrderItemId });
      });
      this.$api.onlineOrderModifierGroups
        .postModifierItems(this.id, ids)
        .then(() => {
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => {
          this.$func.message.error(error.data.Message);
        });
    },
    saveModifierTastes(items) {
      let ids = [];
      items.forEach((item) => {
        ids.push({ TasteId: item.TasteId });
      });
      this.$api.onlineOrderModifierGroups
        .postModifierItems(this.id, ids)
        .then(() => {
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => {
          this.$func.message.error(error);
        });
    },
  },
};
</script>
