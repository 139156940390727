export function getAll() {
  return [
    {
      value: 0,
      name: "LowestPrice",
      text: "最低價",
    },
    {
      value: 1,
      name: "HighestPrice",
      text: "最高價",
    },
  ];
}
export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
